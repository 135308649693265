import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({ 
    name: 'user', 
    initialState: { 
        name: 'N/A', 
        email: 'N/A',
        uid: 'N/A', 
        toggleActive: false
    }, 
    reducers: {
        update: (state, action) => { 
            // console.log(state, action)
            state.name = action.payload.name; 
            state.email = action.payload.email; 
            state.uid = action.payload.uid; 
        }, 
        remove: (state) => { 
            state = {}; 
        }, 
        updatedUid: (state, action) =>  {
            state.updatedUid =  action.payload.updatedUid; 
        },
        updatedShare: (state, action) =>  {
            console.log(state, action)
            state.shareUpdate = action.payload.shareUpdate; 
        },
        newsToggleActive: (state, action) => {
            state.newsToggleActive = action.payload.newsToggleActive; 
        }
    }

})

export const { update, updatedUid, updatedShare, newsToggleActive } = UserSlice.actions;
export default UserSlice.reducer;  