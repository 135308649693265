import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import BusinessToggle from "../../components/businessToggle/BusinessToggle";
import Rightbar from "../../components/rightbar/Rightbar";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import axios from "axios";
import "./trade.css";

export default function PremiumDetails() {
  const { id } = useParams();
  const [apiData, setApiData] = useState([]);

  const getData = async () => {
    try {
      const response = await axios.get(
        `https://ahmedabadsubha.com/api/mobile/classified-jobs/${id}`
      );
      setApiData(response?.data?.data);
    } catch (e) {
      console.log("error from free listing page", e);
    }
  };

  const slides = [];
  for (let i = 1; i <= 10; i++) {
    const image = apiData?.[`image_${i}`];
    if (image !== null) {
      slides.push(image);
    }
  }

  useEffect(() => {
    getData();
  }, []);
  console.log(apiData, slides);

  return (
    <>
      <div>
        <Topbar />
        <div className="businessContainer">
          <Sidebar />
          <div className="tradeWrapper">
            <div>
              <>
                <div className="tradeRedirectImgContainer">
                  {apiData.image_2 ? (
                    <div className="tradeRedirectMainImg">
                      <ImageSlider slides={slides} />
                    </div>
                  ) : (
                    <img
                      src={apiData.image_1}
                      alt="business img"
                      className="tradeRedirectMainImg"
                    />
                  )}
                </div>

                <div className="premiumInfoContainer">
                  <div>
                    <p style={{ fontWeight: "bold" }}>Name:</p>
                    <p>{apiData?.userInfo?.name}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Business Name:</p>
                    <p>{apiData?.business_name}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Brand Name:</p>
                    <p>{apiData?.brand_name}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Description:</p>
                    <p>{apiData?.description}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Business Address:</p>
                    <p>{apiData?.business_address}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Business Registration Number:</p>
                    <p>{apiData?.business_registration_number}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Business Type:</p>
                    <p>{apiData?.business_type}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Target Audience:</p>
                    <p>{apiData?.target_audience}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>Target Locations:</p>
                    <p>{apiData?.target_locations}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>PAN Number:</p>
                    <p>{apiData?.pan_number}</p>
                  </div>
                  <hr style={{ marginTop: 5, color: "gray" }} />
                  <div>
                    <p style={{ fontWeight: "bold" }}>GST Number:</p>
                    <p>{apiData?.gst_number}</p>
                  </div>
                  {/* <hr style={{ marginTop: 5, color: "gray" }} /> */}
                </div>
              </>
            </div>
          </div>
          <Rightbar />
        </div>
      </div>
    </>
  );
}
