import Home from "./pages/home/Home";
// import Profile from './pages/profile/Profile';
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import Business from "./pages/business/Business";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import News from "./pages/news/News";

import ProtectedRoutes from "./context/ProtectedRoutes";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Jobs from "./pages/jobs/Jobs";
import JobPreview from "./components/trade/JobPreview";
import JobDetail from "./pages/jobs/JobDetail";
import Recruiter from "./pages/recruiter/Recruiter";
import RecruiterForm from "./pages/forms/RecruiterForm";
import JobForm from "./pages/forms/JobForm";
import Reels from "./pages/reels/Reels";
import Loader from "./loader/Loader";
import Matri from "./pages/matrimonial/Matri";
import Badge from "./pages/badge/Badge";
import BusinessAcc from "./pages/businessAccount/BusinessAcc";
import AdvertiseUs from "./pages/advertiseUs/AdvertiseUs";
import Contact from "./pages/contact/Contact";
import ProfileNew from "./pages/profile/ProfileNew";

import "./App.css";
import Quiz from "./pages/quiz/Quiz";
import PlayQuiz from "./pages/quiz/PlayQuiz";
import OtherProfile from "./pages/otherProfile/OtherProfile";
import ProfileEdit from "./pages/profile/ProfileEdit";
import Payment from "./pages/payment/Payment";
import PaymentHis from "./pages/paymentHis/PaymentHis";
import AdvertiseUsAlt from "./pages/advertiseUs/AdvertiseUsAlt";
import SeparatePost from "./pages/separatePost/SeparatePost";
import Bride from "./pages/matrimonial/Bride";
import ReelUpload from "./pages/reels/ReelUpload";
import Listing from "./pages/listing/Listing";
import Premium from "./pages/premium/Premium";
import ListingIndividual from "./pages/listing/listingIndividual/ListingIndividual";
import PremiumDetails from "./pages/DetailsPage/PremiumDetails";
import PrivacyPolicy from "./pages/policies/PrivacyPolicy";
import TermsOfUse from "./pages/policies/TermsOfUse";
import CommunityStandards from "./pages/policies/CommunityStandards";
import BusinessPolicy from "./pages/policies/BusinessPolicy";
import Disclaimer from "./pages/policies/Disclaimer";
import AdvertisementPolicy from "./pages/policies/AdvertisementPolicy";
import RefundAndCancellation from "./pages/policies/RefundAndCancellation";
import DownloadApp from "./pages/downloadApp/downloadApp";
import ServicesOffered from "./pages/servicesOffered/ServicesOffered";

// import { auth } from "./firebase";

function App() {
  const loggedIn = window.localStorage.getItem("isLoggedIn");

  return (
    <Router>
      <UserAuthContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path='/' element={loggedIn ? <Home/> : <Login/>}/> */}
          <Route
            path="/profile"
            element={
              <ProtectedRoutes>
                <ProfileNew />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoutes>
                <OtherProfile />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/home"
            element={
              <ProtectedRoutes>
                <Home />
              </ProtectedRoutes>
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route
            path="/news"
            element={
              <ProtectedRoutes>
                <News />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business"
            element={
              <ProtectedRoutes>
                <Business />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business/listing"
            element={
              <ProtectedRoutes>
                <Listing />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business/premium"
            element={
              <ProtectedRoutes>
                <Premium />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business/premium/:id"
            element={
              <ProtectedRoutes>
                <PremiumDetails />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business/jobsPreview"
            element={
              <ProtectedRoutes>
                <JobPreview />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business/jobs"
            element={
              <ProtectedRoutes>
                <Jobs />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business/jobDetail"
            element={
              <ProtectedRoutes>
                <JobDetail />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/business/recruiter"
            element={
              <ProtectedRoutes>
                <Recruiter />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/badge"
            element={
              <ProtectedRoutes>
                <Badge />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/businessacc"
            element={
              <ProtectedRoutes>
                <BusinessAcc />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/advertiseUs"
            element={
              <ProtectedRoutes>
                <DownloadApp />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/advertiseUsAlt"
            element={
              <ProtectedRoutes>
                <AdvertiseUsAlt />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/contactUs"
            element={
              <ProtectedRoutes>
                <Contact />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/quiz"
            element={
              <ProtectedRoutes>
                <Quiz />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/playQuiz"
            element={
              <ProtectedRoutes>
                <PlayQuiz />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/editprofile"
            element={
              <ProtectedRoutes>
                <ProfileEdit />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/payment"
            element={
              <ProtectedRoutes>
                <Payment />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/payment/history"
            element={
              <ProtectedRoutes>
                <PaymentHis />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/post/:postid"
            element={
              <ProtectedRoutes>
                <SeparatePost />
              </ProtectedRoutes>
            }
          />
          {/* <Route path="/business/matrimonial/form" element={<RecruiterForm/>}/> */}
          <Route path="/business/jobs/form" element={<JobForm />} />
          <Route path="/reels" element={<Reels />} />
          <Route path="/reels/upload" element={<ReelUpload />} />
          <Route path="/loader" element={<Loader />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/community-standards" element={<CommunityStandards />} />
          <Route path="/business-policy" element={<BusinessPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route
            path="/advertisement-policy"
            element={<AdvertisementPolicy />}
          />
          <Route
            path="/refund-and-cancellation-policy"
            element={<RefundAndCancellation />}
          />
          <Route
            path="/services-offered"
            element={<ServicesOffered />}
          />
          {/* <Route path="/matrimonial" element={<Matri/>}/> */}
          {/* <Route path="/matrimonial/bride" element={<Bride/>}/> */}
        </Routes>
      </UserAuthContextProvider>
    </Router>
  );
}

export default App;
