import React from 'react'
import './trade.css'

import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
// import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { Link, useNavigate } from 'react-router-dom';

import LockIcon from '@mui/icons-material/Lock';

export default function TradeJobs(props) {

  // const toggle = () => {
  //   setTradeOpen(!tradeOpen)
  //   if (tradeOpen === false) {
  //     setSee("More")
  //   } else{
  //     setSee("Less") 
  //   } 
  // }

  // const[tradeOpen, setTradeOpen] = useState(true); 
  // const[see, setSee] = useState('More'); 
  
  // console.log(tradeOpen)

  const navigate = useNavigate(props); 
  const toJobDetail = () => {
    navigate('/business/jobDetail', {state: {
      photo: props.trade.photo,
      name: props.trade.name, 
      resume: props.trade.resume, 
      profile: props.trade.current_profile, 
      education_qualification: props.trade.education_qualification,
      experience: props.trade.work_experience, 
      ctc: props.trade.ctc, 
    }})
  }

  // console.log(props.trade); 

  return (
    <>
     <div className='trade-wrapper'>  
       <div className="trade">
        <div className="tradeWrapper">
            <div className="tradeTop">
                
                <img src={props.trade.photo} alt="business img" className="jobTradeMainImg"/>
              
                <div className="JobTradeTopRight">
                  <div>
                    <div className="headingTop">
                      <h3 className='tradeHeading'>{props.trade.name}</h3>  
                      <div className="eyeCount">
                        <RemoveRedEyeIcon/>
                        <p>{props.trade.views}</p>
                      </div>
                    </div>
                    <p className='tradeDesc'>{props.trade.current_profile}</p>  
                    <div className="tradeDetailBox">
                      <WorkHistoryIcon htmlColor='grey'/>
                      <p className='DetailDesc'>{props.trade.work_experience}</p>
                    </div>          
                    <div className="tradeDetailBox">
                      <HorizontalSplitIcon htmlColor='grey'/>
                      <p className='DetailDesc'>{props.trade.education_qualification}</p>
                    </div>     

                    {/* <Link className='tradeSeeMore' to='/business/jobDetail'>See More</Link> */}
                    {props.job ?
                      <p className='tradeSeeMore' to='/business/jobDetail' onClick={() => toJobDetail()}>See More</p>
                        : 
                      <p className='tradeSeeMore' to='/business/jobDetail'> <LockIcon/> See More</p>
                    }

                  </div> 
                  {/* <div className={`subDetailContainer ${tradeOpen? 'tradeActive' : 'nothinng'}`}>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Description</p>
                      <p style={{ width: '60%'}}>{'props.trade.description'}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Business Type</p>
                      <p style={{width: '60%'}}>{'props.trade.business_type'}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Target Locations</p>
                      <p style={{width: '60%'}}>{'props.trade.target_locations'}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Business Reg No.</p>
                      <p style={{width: '60%'}}>{'props.tration_number'}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Business Reg Date</p>
                      <p style={{width: '60%'}}>{'props.'}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>GST</p>
                      <p style={{width: '60%'}}>{'props.trade.gst_number'}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>PAN</p>
                      <p style={{width: '60%'}}>{'12981927816'}</p>
                    </div>
                  </div> */}
                </div>

                {/* <div className="tradeTopExtremeRight">
                  <div className="tradeChat">
                    <ChatBubbleIcon/>
                    Chat
                  </div>
                  <div onClick={() => toggle()} className="tradeSeeMore">
                    See {see}
                  </div>
                </div> */}
            </div>
            <div className="tradeCenter">
                <p className="tradeText">Updated at: {props?.trade?.updated_at.slice(0, 10)}</p>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}
