import React, { useState } from 'react'
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import './ads.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import ImageSlider from '../imageSlider/ImageSlider.jsx'

export default function Classified(props) {

  const active = props.post.images.length === 0; 
  // console.log(active)
  // console.log(props.post.images)
  
  return (
    <div>
        <div className="adsWrapper">
            
            <div className="ads-container">
                
                {/* <hr className='ads-break' /> */}
                <div className="adsCenter">
                  {props.post.images[0] &&                  
                  <>
                  <div style={{ flex: "2" }} className='ads-img'>
                    <ImageSlider slides={props.post.images} className='ads-img'/>
                    {/* <img src={props.post.images[0]} alt="ad" className='ads-img' /> */}
                  </div>
                  </>
                  }
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: '4' }}>
                  <div>
                    <div className="adsTop">
                    {props.post.title}
                    {props.post.business_name ? 
                      <>
                      <BusinessIcon className='classifiedAdsIcons' htmlColor='var(--yellow)'/>
                      </> 
                      : 
                      <>
                      <PersonIcon className='classifiedAdsIcons' htmlColor='green'/>
                      </>
                    }
                    </div>
                    <div className="ads-info">
                      {props.post.description.length > 60 ? 
                      <>
                        {props.post.description.slice(0, 63)+"..."}
                      </>
                      :
                      <>
                      {props.post.description}
                      </>
                      }
                    </div>
                    {props.post.price &&
                    <p style={{ paddingLeft: '10px', fontSize: "12px" }}>₹ {props.post.price}</p>
                    }
                  </div>
                  {props.post.google_map_link && 
                  <div className={active ? 'FalseClassifiedAdsLocation' : 'classifiedAdsLocation'}>
                    <LocationOnIcon style={{ fontSize: '15px' }} htmlColor='var(--primary)'/>
                    <a style={{ textDecoration: 'none', color: 'var(--primary)', fontSize: '13px' }} target='_blank' href={props.post.google_map_link} rel="noreferrer">Get Directions</a>
                  </div>
                  }
                  <div className={`ads-chat`}>Chat</div>
                </div>
                </div>

            </div>
      </div>
    </div>
  )
}
