import React, { useState } from "react";
import "./post.css";
import axios from "axios";
import { useSelector } from "react-redux";

import Loader from "../../loader/Loader";
import CancelIcon from '@mui/icons-material/Cancel';

export default function PostModal({ closeModal, id, likes }) {

  const uid = window.localStorage.getItem("uid"); 
  const [loading, setLoading] = useState(true); 

  // const user = useSelector((state) => state.user)

  // axios.post(`https://ahmedabadsubha.com/api/mobile/selfie/comment${""}`, { 
  //   comment: comment, 
  //   user: user.uid,
  //   // parent: replyId
  // }).then (res => {
  //   console.log(res); 
  // }).catch(error => {
  //   console.log(error);
  // })
  

  const [active, setActive] = useState(false); 

  const likeToggle = () => {
    setActive(false); 
  }

  const commentToggle = () => {
    setActive(true); 
    getComments(); 
  }

  const [commentData, setCommentData] = useState([]); 
  const getComments = async () => {
      await axios.get(`https://ahmedabadsubha.com/api/mobile/selfie/comment/${id}`)
      .then (res => {
        setCommentData(res.data.data)
        setLoading(false)
      })
      .catch( e => {
        console.log(e); 
      })
      
    }

    const [comment, setComment] = useState("");
    const postComment = async () => {
      setActive(true); 
  
      await axios.post(`https://ahmedabadsubha.com/api/mobile/selfie/comment/${id}`, {
        comment: comment, 
        user: uid,
        parent: null
      })
      .then(res => {
        console.log(res)
      })
      .catch (e => console.log(e))
      
      setLoading(true); 
      getComments(); 
      setComment(""); 
    }


    // console.log(likes)


    // console.log(commentData.length); 
    // setTimeout(() => {
    //   console.log('this is from timeout' + commentData); 
    // }, 5000);

  return (
    <>
      <div className="modal-wrapper" onClick={closeModal}></div>
      <div className="greeting-modal-container">
      <CancelIcon className='postModalCancel' onClick={closeModal} />
        <header>
          <div className="postModalHeading">
            <div onClick={likeToggle} className="postmodalToggle">
              <p className={active ? '' : 'likeToggleActive'}>Likes</p>
            </div>
            <div onClick={commentToggle} className="postmodalToggle">
              <p className={active ? 'likeToggleActive' : ''}>Comments</p>
            </div>
          </div>
        </header>
        <div className="postModal" style={{ display: "flex" }}>
          {active ?
          <div className="eachCommentContainer">
          {loading && <Loader/>}
          <>
          {commentData.map((data) => (
            <>
              <div className="eachComment" key={data.id}>
                <p style={{ fontWeight: 'bold', color: 'var(--primary)' }}>{data.user_name}</p>
                <p>{data.comment}</p>
              </div>
            <hr />
            </>
          ))}
          </>
          </div> 
          : 
          <div style={{ display: 'flex', flexDirection: "column", gap: '8px' }}>
          {likes.map((data) => {
            return (
            <div className="eachLikeContainer">
              <p className="eachLikeImg">{data.name.slice(0, 1)}</p>
              <p key={data.id}>{data.name}</p>
            </div>
            )
          })}

          </div>
          }


        </div>
        <div className="postCommentBoxContainer">
          <input className="postCommentInput" type="text" placeholder="Comment Here"  value={comment} onChange={(e) => setComment(e.target.value)} />
          <button onClick={postComment} className="postButton">Post</button>
        </div>
      </div>
    </>
  );
}
