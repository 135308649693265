import { createContext, useContext, useEffect, useState } from "react"
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateProfile
} from "firebase/auth"
import { auth } from "../firebase"
import { useDispatch } from "react-redux";
import { update, updatedUid } from "../redux/UserSlice";

const userAuthContext = createContext(); 

export function UserAuthContextProvider( {children} ) {

    const dispatch = useDispatch(); 

    const [user, setUser] = useState("");

    async function signUp(email, password, name) {
            let uid = ""; 

            await createUserWithEmailAndPassword(auth, email, password)
            .then((res) => { 
                console.log(res)
                const user = res.user;
                updateProfile (user, { 
                    displayName: name
                }) 
                uid = user.uid; 

            })
            return uid;

    }   

    function logIn(email, password) {   
        return  signInWithEmailAndPassword(auth, email, password)
        .then((res) => {
            console.log(res); 
            const name = res.user.displayName; 
            const email = res.user.email; 
            const uid = res.user.uid; 
            console.log(name, email, uid)
            
            window.localStorage.setItem("name", name); 
            window.localStorage.setItem("email", email);
            window.localStorage.setItem("uid", uid)

            dispatch(update({name, email, uid})); 
            // console.log(uid)
        }); 

        
    }



    function logOut () {
        return signOut(auth); 
    }

    function profileName (name) {
        console.log(name)
        return updateProfile(user, {displayName: name}); 
    } 

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser); 
        })
        return () => {
            unsubscribe(); 
        }
    }, [])

    return (
        <userAuthContext.Provider value={{user, signUp, logIn, logOut, profileName}}>
            {children}
        </userAuthContext.Provider>
    )
}

export function useUserAuth() {
    return useContext(userAuthContext); 
}

