import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./topbarhehe.css";
import SearchIcon from "@mui/icons-material/Search";
import EmailIcon from "@mui/icons-material/Email";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DropdownItem from "./DropdowmItem";
import logo2 from "../../assets/logo2.png";
import logoText from "../../assets/logoText.jpeg";
import axios from "axios";
import playstore from "../../assets/playstore.png";
import applelogo from "../../assets/applelogo.png";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

export default function Topbar(props) {
  const [open, setOpen] = useState(false);

  const name = window.localStorage.getItem("name");
  const email = window.localStorage.getItem("email");

  const navigate = useNavigate();
  const logout = () => {
    console.log("logout Clicked");
    window.localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  let profile_img = window.localStorage.getItem("profile_img");

  const [searchUp, setSearchUp] = useState(false);

  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/home" ||
      location.pathname === "/news"
    ) {
      setSearchUp(false);
    } else {
      setSearchUp(true);
    }
  }, [location]);

  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://ahmedabadsubha.com/api/mobile/user/list/all?query=${search}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => {
        setSearchData(data.data.data);
      })
      .catch((e) => console.log(e));
  }, [search]);

  const [notiOpen, setNotiOpen] = useState(false);
  const openNotifications = () => {
    setNotiOpen(!notiOpen);
  };

  const [notiData, setNotiData] = useState([]);
  const uid = window.localStorage.getItem("uid");
  useEffect(() => {
    axios
      .get(`https://ahmedabadsubha.com/api/mobile/notification/${uid}`)
      .then((data) => {
        setNotiData(data.data.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [uid]);

  const [openMessage, setOpenMessage] = useState(false);
  const openMessageFunction = () => {
    setOpenMessage(!openMessage);
  };

  const android = () => {
    var link =
      "https://play.google.com/store/apps/details?id=com.shadow.trendingsaround&pcampaignid=web_share";
    window.open(link, "_blank");
  };
  const apple = () => {
    var link = "https://apps.apple.com/in/app/trendings-around/id6465681185";
    window.open(link, "_blank");
  };

  const notificationClick = (selfie, reel) => {
    console.log(`selfie -> ${selfie}`, `reel -> ${reel}`);

    if (selfie) {
      console.log("this is me selfie");
      navigate(`/post/${selfie}`);
    } else if (reel) {
      console.log("this is me reel, nothing yet");
    }
  };

  const toProfile = () => {
    navigate("/profile");
  };

  return (
    <>
      <div className="navbar">
        <div className="left">
          {/* <Link to="/" style={{textDecoration: "none"}} >  
                <span className='logo-name'>Trendings</span>
            </Link> */}
          <Link className="topbar-logo-link" to="/home">
            <img src={logo2} alt="" className="topbar-logo-img" />
            <img
              src={logoText}
              alt="trendings around"
              className="topbar-logo-text"
            />
          </Link>
          {/*             
            <Link to='/home' style={{ color: 'black' }}><div className="home-icon-topbar"><HomeIcon/></div></Link>
            <div className="window-icon-topbar"><WindowIcon/></div> */}

          <div className={`search ${searchUp ? "searchUp" : ""}`}>
            <SearchIcon className="navbar-search" />
            <input
              type="text"
              placeholder="search"
              className="search-input-box"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {search && (
            <>
              <div className="notiClose" onClick={() => setSearch("")}></div>
              <div className="userDropdown">
                {searchData?.map((data) => (
                  <div className="userContainer" key={data.id}>
                    {data.image ? (
                      <img
                        src={data.image}
                        className="searchUserImg"
                        alt="user"
                      />
                    ) : (
                      <div className="userImgNo">
                        {data.name.slice(0, 1).toUpperCase()}
                      </div>
                    )}
                    <p>{data.name}</p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="right">
          <div
            className="nav-email-icon"
            style={{ cursor: "pointer" }}
            onClick={openMessageFunction}
          >
            <EmailIcon />
          </div>
          {openMessage && (
            <>
              <div className="notiClose" onClick={openMessageFunction}></div>
              <div className="messageContainer">
                <p
                  style={{
                    color: "var(--primary)",
                    textAlign: "center",
                    paddingTop: "5px",
                  }}
                >
                  Download now to use this Feature
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "auto",
                    cursor: "pointer",
                    paddingTop: "10px",
                  }}
                >
                  <img
                    src={playstore}
                    onClick={android}
                    style={{ width: "50px" }}
                    alt=""
                  />
                  <img
                    src={applelogo}
                    onClick={apple}
                    style={{ width: "90px" }}
                    alt=""
                  />
                </div>
              </div>
            </>
          )}

          <div className="nav-notification-icon" onClick={openNotifications}>
            <div className="redDot"></div>
            <NotificationsIcon />
          </div>

          {notiOpen && (
            <div className="notiContainer">
              {notiData ? (
                <>
                  {notiData.map((data) => (
                    <>
                      <div
                        className="notiClose"
                        onClick={openNotifications}
                      ></div>
                      <div
                        key={data.id}
                        style={{
                          padding: "5px 15px 5px 15px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          notificationClick(data.selfie, data.reel)
                        }
                      >
                        <p
                          style={{
                            color: "var(--primary)",
                            fontSize: "13px",
                            fontWeight: "450",
                          }}
                        >
                          {data.notification}
                        </p>
                        <p style={{ color: "darkgray", fontSize: "10px" }}>
                          {data.updated_at.slice(0, 10)}{" "}
                          <span
                            style={{
                              color: "darkgray",
                              fontSize: "10px",
                              paddingLeft: "5px",
                            }}
                          >
                            {data.updated_at.slice(11, 16)}
                          </span>
                        </p>
                        {/* <hr style={{ marginTop: "5px", color: "lightgray" }}/> */}
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    color: "var(--primary)",
                    paddingTop: "20px",
                  }}
                >
                  No New Notifications
                </p>
              )}
            </div>
          )}

          <div className="user">
            {/* <span onClick={toProfile} className="logo-user-name">
              {name}
            </span>
            <img
              src={profile_img}
              alt=""
              className="nav-logo-img"
              onClick={() => {
                setOpen(!open);
              }}
            /> */}

            {true && (
              // <div
              //   className="dropdownBlank"
              //   onClick={() => {
              //     setOpen(!open);
              //   }}
              // ></div>
              <div className="three_dots_icon_topbar" onClick={() => {
                    setOpen(!open);
                  }}>
                <MoreHorizIcon/>
              </div>
            )}
            <div className={`dropdown-menu ${open ? "active" : "inactive"}`}>
              <div>
                <h3 className="dropdown-heading">
                  {name}
                  <br />
                  <span className="logo-user-name">{email}</span>
                  {/* <br /><span>description</span> */}
                </h3>

                <ul className="dropdown-ul">
                  <DropdownItem text={"My Profile"} to={"/profile"} />
                  {/* <DropdownItem text={"Payment"} to={"/payment"} /> */}
                  <DropdownItem text={"Feed"} to={"/home"} />
                  <DropdownItem text={"Around"} to={"/news"} />
                  <DropdownItem text={"Business"} to={"/business"} />
                  <DropdownItem text={"Chats"} to={"/"} />
                  {/* <DropdownItem
                    text={"Payment History"}
                    to={"/payment/history"}
                  /> */}
                  <DropdownItem text={"Request For Badge"} to={"/badge"} />
                  <DropdownItem
                    text={"Apply For Business Acc"}
                    to={"/businessacc"}
                  />
                  <DropdownItem
                    text={"Advertise With Us"}
                    to={"/advertiseUs"}
                  />
                  <DropdownItem
                    text={"Privacy Policy"}
                    to={"/privacy-policy"}
                  />
                  <DropdownItem text={"Terms Of Use"} to={"/terms-of-use"} />
                  <DropdownItem
                    text={"Community Standards"}
                    to={"/community-standards"}
                  />
                  <DropdownItem
                    text={"Business Policy"}
                    to={"/business-policy"}
                  />
                  <DropdownItem text={"Disclaimer"} to={"/disclaimer"} />
                  <DropdownItem
                    text={"Advertisement Policy"}
                    to={"/advertisement-policy"}
                  />
                  <DropdownItem
                    text={"Refund & Cancellation"}
                    to={"/refund-and-cancellation-policy"}
                  />
                  <DropdownItem text={"Contact Us"} to={"/contactUs"} />
                  <DropdownItem text={"Services Offered"} to={"/services-offered"} />
                  <div
                    onClick={logout}
                    style={{ color: "var(--primary)", cursor: "pointer" }}
                    className="dropdown-item"
                  >
                    <span style={{ color: "red" }}>Logout</span>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
