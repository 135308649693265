import React from "react";
import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'
// import axios from 'axios'

import './badge.css'
import { useState } from "react";

export default function Badge () {

    const [active, setActive] = useState(true); 


    return (
        <>
        <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="badgeWrapper">
                <p className="batchHeading">Choose a Badge</p>
                <div className="badgeToggle">
                    <p className={`chooseBadge ${active && 'dynamic'}`} onClick={() => setActive(true)}>Bonafide Citizens</p>
                    <p className={`chooseBadge ${active ? '' : 'dynamic'}`} onClick={() => setActive(false)}>Government Official</p>
                </div>
                {active && 
                
                    <div className="bonafideSection">
                        <div className="badgeEmail">
                            <p style={{ padding: '0 0 10px 0' }} className="batchHeading">Email</p>
                            <div className="badgeEmailInputContainer">
                                <input type="email" placeholder="Enter Your Email" className="badgeEmailInput" />
                                <p className="badgeInputButton">Send OTP</p>
                            </div>
                        </div>
                        <div className="BonafideID">
                            <p className="batchHeading" style={{ padding: '20px 0 10px 0' }}>Upload any Government issued ID</p>
                        </div>
                        <p className="badgeUpload">Upload Document</p>
                        <p className="badgeUpload">Request For Badge</p>
                    </div>
                }

                {active ? '' : 

                    <div className="bonafideSection">
                        <div className="badgeEmail">
                            <p style={{ padding: '0 0 10px 0' }} className="batchHeading">Email</p>
                            <div className="badgeEmailInputContainer">
                                <input type="email" placeholder="Enter Your Email" className="badgeEmailInput" />
                                <p className="badgeInputButton">Send OTP</p>
                            </div>
                        </div>
                        <div className="BonafideID">
                            <p className="batchHeading" style={{ padding: '20px 0 10px 0' }}>Upload any Government Department issued official ID</p>
                        </div>
                        <p className="badgeUpload">Upload Document</p>
                        <p className="badgeUpload">Request For Badge</p>
                    </div>
                }

            </div>
            <Rightbar/>
        </div>
    </div>
        </>
    )
}