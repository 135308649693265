import React from 'react'
import './trade.css'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import BusinessToggle from '../../components/businessToggle/BusinessToggle'
import Rightbar from '../../components/rightbar/Rightbar'
import { Link } from 'react-router-dom'

export default function JobPreview() {
  return (
    <>

        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="tradeWrapper">
                <BusinessToggle/>

                <div className='jobPreviewContainer'>
                    <div className="PreviewSection">
                        <Link style={{textDecoration: 'none'}} to='/business/recruiter'>
                            <p className='PreviewSectionHeading' >Are you a Recruiter?</p>
                        </Link>
                        <Link style={{textDecoration: 'none'}} to='/business/recruiter/form'>
                            <p style={{ color: 'black' }}>Register</p>
                        </Link>
                    </div>
                    <div className="PreviewSection">
                        <Link style={{textDecoration: 'none'}} to='/business/jobs'>
                            <p className='PreviewSectionHeading'>Are you a Job seeker?</p>
                        </Link>
                        <Link style={{textDecoration: 'none'}} to='/business/jobs/form'>
                            <p style={{ color: 'black' }}>Apply</p>
                        </Link>
                    </div>
                </div>
            </div>
            <Rightbar/>
        </div>

    </>
  )
}
