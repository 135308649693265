import React, { useState, useEffect } from 'react'

import TopbarNews from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'
import Blog from '../../components/newsBlog/Blog'

import axios from 'axios'

import './news.css'
import AroundToggle from '../../components/aroundToggle/AroundToggle'
import Loader from '../../loader/Loader'

export default function News() {

  const [apiData, setApiData] = useState([]);  
  const [searchNews, setSearchNews] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6); 

  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    axios
      .get(`https://ahmedabadsubha.com/api/mobile/news`)
      .then((getData) => {
        setApiData(getData.data.results);
        setLoading(false); 
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); 
      });
  }, [currentPage]); 

  const lastNewsIndex = currentPage * itemsPerPage; 
  const firstNewsIndex = lastNewsIndex - itemsPerPage; 
  const currentNews = apiData.slice(firstNewsIndex, lastNewsIndex); 

  const showMore = () => {
    setCurrentPage(currentPage + 1);
  }

  console.log(currentNews); 
  
  
  return (
    <div>
      <TopbarNews ChangeWord={word => setSearchNews(word)}/>

      <div className="homeContainer">

        <Sidebar/>
        <div className="blogWrapper blogWrapperTop">
          <AroundToggle/>
          
          {apiData.filter((value) => {
            if (searchNews === "") {
              return value; 
            } else if (value.category.toLowerCase().includes(searchNews.toLocaleLowerCase())) {
              return value;
            }
          })
          .map((data) => {
            return(
              <Blog key={data.id} post={data}/>
            )
          })}
          {/* <p onClick={showMore}>Show More</p> */}
          {loading && <Loader/>}
        </div>

        <Rightbar/>
        
      </div>
    </div>
  )
}
