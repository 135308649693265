import React, { useEffect, useState } from "react";
import "./post.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
// import axios from 'axios';

// import CancelIcon from '@mui/icons-material/Cancel';
// import Mymodal2 from '../share/Mymodal2';
import PostModal from "./PostModal";
import { useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useNavigate } from 'react-router-dom'

export default function Post({ post, props }) {
  // console.log(post);

  // const [like,setLike] = useState(post.likes.length)
  const [isliked, setisLiked] = useState(false);

  // const likeHandler =() => {
  //   setLike(isliked ? like-1 : like + 1);
  //   setisLiked (!isliked);
  // }

  const [likes, setLikes] = useState(post.likes.length);
  // const user = useSelector((state) => state.user);
  const uid = window.localStorage.getItem("uid")
  const userName = window.localStorage.getItem("name"); 

  const likeHandler = () => {
    if (isliked) {
      setLikes(likes - 1);
      setisLiked(false);
    } else {
      setLikes(likes + 1);
      setisLiked(true);
    }

    // ahmedabadsubha.com/api/mobile/selfie - post upload
    axios
      .post("https://ahmedabadsubha.com/api/mobile/reaction/selfie", {
        reaction: "like",
        uid: uid,
        selfie_id: post.id,
      })
      .then((response) => console.log("okay"))
      .catch((error) => {
        console.log("error: ", error);
      });
  };
  // console.log("from post", uid, post.id); 
  const isNameThere = post.likes.some(obj => obj.uid === uid);
  console.log("0000000 asdasdasasd", post.likes, userName)

  useEffect(() => {
    if(isNameThere) {
      setisLiked(true); 
    } else {
      setisLiked(false)
    }
  }, [])

  // const shareButton = () => { 
  //   navigate(`/post/${post.id}`)
  // }

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  const [showMore, setShowMore] = useState(false);

  const showMoreItems = () => {
    setShowMore(!showMore);
  };

  // console.log(post.likes.length);

  const [showReport, setShowReport] = useState(false);
  const Report = () => {
    setShowReport(!showReport);
    setReportExpand(false); 
  };

  const [reportExpand, setReportExpand] = useState(false); 
  const ReportExpand = () => {
    setReportExpand(!reportExpand); 
  }

  const handleDelete = () => {
    
    axios.delete(`https://ahmedabadsubha.com/api/mobile/selfie/delete/${post.id}`)
    .then (status => {
      console.log("deleted successfully")
    }) 
    .catch (e => {
      console.log(e)
    })

  }
  const user_id_local = window.localStorage.getItem("uid")
  // console.log(post.user_uid, user.uid, user_id_local)

  const navigate = useNavigate(); 
  const userProfile = () => {
    navigate('/users', {
      state: {
        profileImage: post.user.profile_image, 
        name: post.user.name, 
        uid: post.user.uid
      }
    }); 
  }
  // console.log("this is the profile image --> " + post.user.profile_image)
  // console.log(post.likes)

  const local_uid = window.localStorage.getItem("uid"); 
  // console.log("this is local uid --> " + local_uid); 
  
  const [reportMessage, setReportMessage] = useState(""); 
  const [reportButtonDisabled, setReportButtonDisabled] = useState(false); 

  const handleReport = async () => {
    setReportButtonDisabled(true)
    await axios.post('https://ahmedabadsubha.com/api/mobile/report/selfie', {
      selfie: post.id,
      user: local_uid, 
      reason: reportMessage
    })
    .then (res => {
      console.log(res)
      toast.success("Report Sent", {
        position: "top-center"
      })
    })
    .catch (e => {
      console.log(e)
      setReportButtonDisabled(false); 
    })
  }

  const currentUrl = window.location.href; 
  const modCurrentUrl = currentUrl + "post/" + post.id; 

  const [showCopied, setShowCopied] = useState(false); 
  const CopyUrl = () => {
      navigator.clipboard.writeText(modCurrentUrl); 
      setShowCopied(true); 
      setTimeout(() => {
          setShowCopied(false); 
      }, 1000);
  }
  const [showShare, setShowShare] = useState(false); 
  const shareButton = () => {
      setShowShare(!showShare); 
  }


  return (
    <div>
      <div className="post">
        <div className="postWrapper">
          <div className="postTop">
            <div onClick={userProfile} className="postTopLeft">
              <div
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  backgroundColor: "var(--primary)",
                }}
              >
                {post.user.profile_image ? (
                  <img
                    src={post.user.profile_image}
                    className="postProfileImg"
                    alt=""
                  />
                ) : (
                  <div
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: "20px",
                      paddingTop: "1.5px",
                    }}
                  >
                    {post.user.name.slice(0, 1).toUpperCase()}
                  </div>
                )}
              </div>
              <span className="postUserName">{post.user.name}</span>
              {/* <span className="postDate">{post.date}</span> */}
            </div>
            <div className="postTopRight" style={{ display: "flex" }}>
              {showReport && (
                <>
                <div className="reportWrapper" onClick={Report}></div>
                <div className="PostReport">
                  <div>
                    {reportExpand ? <div style={{ display: 'flex', gap: '4px' }} onClick={ReportExpand}>
                      <ArrowBackIcon/>
                      <p>Back</p>
                      </div> : 
                      <>
                        <div onClick={ ReportExpand } style={{ display: 'flex', gap:"4px" }}>
                          <ErrorOutlineIcon />
                          <p>Report</p>
                        </div>
                        {post.user_uid === user_id_local && 
                          <div onClick={handleDelete} style={{ display: 'flex', gap:"4px", paddingTop: '8px' }}>
                            <DeleteIcon htmlColor="red"/>
                            <p style={{ color:'red' }}>Delete</p>
                          </div>
                        }
                      </>
                    }
                  </div>
                  {reportExpand && 
                    <div className="reportExpand">
                      <span onClick={() => setReportMessage("Spam")}>Spam</span>
                      <hr />
                      <span onClick={() => setReportMessage("Inappropriate")}>Inappropriate</span>
                      <hr />
                      <span onClick={() => setReportMessage("Fake")}>Fake</span>
                      <hr />
                      <span onClick={() => setReportMessage("Abusive")}>Abusive</span>
                      <hr />
                      <span onClick={() => setReportMessage("Hate Speech")}>Hate Speech</span>
                      <hr />
                      <span onClick={() => setReportMessage("Violence")}>Violence</span>
                      <hr />
                      <span onClick={() => setReportMessage("Nudity")}>Nudity</span>
                      <hr />
                      <span onClick={() => setReportMessage("Sexual Content")}>Sexual Content</span>
                      <hr />
                      <span onClick={() => setReportMessage("Harassment")}>Harassment</span>
                      <hr />
                      <span onClick={() => setReportMessage("Threate")}>Threate</span>
                      <hr />
                      <span onClick={() => setReportMessage("Scam")}>Scam</span>
                      <hr />
                      <span onClick={() => setReportMessage("Bullying")}>Bullying</span>
                      <hr />
                      <span onClick={() => setReportMessage("Harmful")}>Harmful</span>
                      <hr />
                      <span onClick={() => setReportMessage("Suicide")}>Suicide</span>
                      <hr />
                      <span onClick={() => setReportMessage("Other")}>Other</span>

                      {reportMessage && 
                        <button disabled={reportButtonDisabled} onClick={handleReport} className="reportButton">Report</button>
                      }
                    </div>
                  }
                </div>
                </>
              )}
              <MoreVertIcon className="moreIcon" onClick={Report} />
            </div>
          </div>
          <div className="postCenter">
            <span className="postText">
                {showMore ? post.title : `${post.title ? `${post.title.length > 410 ? `${post.title.slice(0, 410) + '...'}` : post.title.slice(0, 410)}` : " " }` }
              <p style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                <span onClick={showMoreItems} style={{ cursor: "pointer", color: "var(--primary)" }}>
                  {post.title.length < 410 ? " " : `${showMore ? "Show Less" : "Show More"}`} 
                </span>
              </p>
            </span>
            <img src={post.image} loading="lazy" alt="" className="postImg" />
          </div>


          <div onClick={(e) => setShowModal(true)} className="likeDisplay">
            <span className="postLikeCounter">
              {likes === 1 ? `${likes} Like` : `${likes} Likes`}
            </span>
            <span className="postCommentText">{post.comment ? `${post.comment} Comment` : '0 Comments'}</span>
          </div>

          <div className="postBottom">

            <div onClick={likeHandler} className="postLike">
              <FavoriteBorderIcon
                className={`likeIcon ${isliked ? "liked" : ""}`}
              />
              <p style={{ marginTop: '5px'}}>{isliked ? "Liked" : "Like"}</p>
            </div>

            <div onClick={(e) => setShowModal(true)} className="postComment">
              <ChatBubbleOutlineIcon className="likeIcon" />
              <p style={{ marginTop: '5px' }}>Comment</p>

            </div>
            <div onClick={shareButton} className="postShare">
              <ShareIcon className="likeIcon" />
              <p style={{ marginTop: '5px' }}>Share</p>
            </div>

            {showShare &&
                        <>
                        <div className='shareBoxWrappeŗ' onClick={shareButton}></div>
                        <div className='shareBoxMainPage'>
                            <p className='shareBoxText'>Share</p>
                            <div className='shareContainer'>
                                <input type="text" className='shareSubBoxOne' value={modCurrentUrl}/>
                                <div onClick={CopyUrl} className='shareBoxButton'>
                                    <ContentCopyIcon/>
                                </div>
                            </div>
                            {showCopied && <p className='shareBoxCopied'>Copied !</p>}
                        </div>
                        </>
                    }

          </div>
          {showModal && <PostModal likes={post.likes} id={post.id} closeModal={closeModal} />}
        </div>
      </div>
      <ToastContainer/>
    </div>
  );
}
