import { useEffect, useState } from 'react'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import './separatePost.css'
import axios from 'axios'

import { useNavigate, useParams } from 'react-router-dom'

import MoreVertIcon from "@mui/icons-material/MoreVert";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ShareIcon from "@mui/icons-material/Share";
import { useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function SeparatePost () {

    const params = useParams(); 
    const uid = window.localStorage.getItem("uid"); 
    const navigate = useNavigate(); 

    const currentUrl = window.location.href; 

    const [apiData, setApiData] = useState("");
    useEffect(() => {
        axios.get(`https://ahmedabadsubha.com/api/mobile/selfie/single/${params?.postid}`)
        .then((getData) => {
            setApiData(getData?.data?.data); 
            console.log(apiData); 
        })
    }, [])

    const [isliked, setisLiked] = useState(false);

    const [likes, setLikes] = useState(0);

    const likeHandler = () => {
        if (isliked) {
        setLikes(likes - 1);
        setisLiked(false);
        } else {
        setLikes(likes + 1);
        setisLiked(true);
        }

        axios
        .post("https://ahmedabadsubha.com/api/mobile/reaction/selfie", {
            reaction: "like",
            uid: uid,
            selfie_id: params?.postid,
        })
        .then((response) => console.log(response))
        .catch((error) => {
            console.log("error: ", error);
        });
    };

    const [showMore, setShowMore] = useState(false);

    const showMoreItems = () => {
        setShowMore(!showMore);
    };

    // console.log(post.likes.length);

    const [showReport, setShowReport] = useState(false);
    const Report = () => {
        setShowReport(!showReport);
        setReportExpand(false); 
    };

    const [reportExpand, setReportExpand] = useState(false); 
    const ReportExpand = () => {
        setReportExpand(!reportExpand); 
    }

    const handleDelete = () => {
        
        axios.delete(`https://ahmedabadsubha.com/api/mobile/selfie/delete/${params?.postid}`)
        .then (status => {
        console.log("deleted successfully")
        }) 
        .catch (e => {
        console.log(e)
        })

    }

    const userProfile = () => {
      navigate('/users', {
        state: {
        //   profileImage: post.user.profile_image, 
        //   name: post.user.name, 
        //   uid: post.user.uid
        }
      }); 
    }

    const [reportMessage, setReportMessage] = useState(""); 
    const [reportButtonDisabled, setReportButtonDisabled] = useState(false); 

    const handleReport = async () => {
        setReportButtonDisabled(true)
        await axios.post('https://ahmedabadsubha.com/api/mobile/report/selfie', {
        selfie: params?.postid,
        user: uid, 
        reason: reportMessage
        })
        .then (res => {
        console.log(res)
        toast.success("Report Sent", {
            position: "top-center"
        })
        })
        .catch (e => {
        console.log(e)
        setReportButtonDisabled(false); 
        })

    
    }

    const [showCopied, setShowCopied] = useState(false); 
    const CopyUrl = () => {
        navigator.clipboard.writeText(currentUrl); 
        setShowCopied(true); 
        setTimeout(() => {
            setShowCopied(false); 
        }, 1000);
    }
    const [showShare, setShowShare] = useState(false); 
    const shareButton = () => {
        setShowShare(!showShare); 
    }

    const [commentData, setCommentData] = useState([]); 
    const commentid = apiData?.id; 
    // console.log("this is comment id", commentid); 
    useEffect(() => {

        axios.get(`https://ahmedabadsubha.com/api/mobile/selfie/comment/${commentid}`)
        .then (res => {
            setCommentData(res.data.data)
            console.log(commentData)
            //   setLoading(false)
        })
        .catch( e => {
            console.log("comment data error", e); 
        })
            
    }, [])

    console.log("commentData", commentData); 


    return (
        <>
        <div>
        <Topbar/>
        <div className="businessContainer">
            <Sidebar/>
            <div className="tradeWrapper">
            <div className="separatePost">
                <div className="postWrapper">
                <div className="postTop">
                    <div onClick={userProfile} className="postTopLeft">
                    <div
                        style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        backgroundColor: "var(--primary)",
                        }}
                    >
                        {apiData?.user?.profile_image ? (
                        <img
                            src={apiData?.user?.profile_image}
                            className="postProfileImg"
                            alt=""
                        />
                        ) : (
                        <div
                            style={{
                            color: "white",
                            textAlign: "center",
                            fontSize: "20px",
                            paddingTop: "1.5px",
                            }}
                        >
                            {apiData?.user?.name.slice(0, 1).toUpperCase()}
                        </div>
                        )}
                    </div>
                    <span className="postUserName">{apiData?.user?.name}</span>
                    {/* <span className="postDate">{post.date}</span> */}
                    </div>
                    <div className="postTopRight" style={{ display: "flex" }}>
                    {showReport && (
                        <>
                        <div className="reportWrapper" onClick={Report}></div>
                        <div className="PostReport">
                        <div>
                            {reportExpand ? <div style={{ display: 'flex', gap: '4px' }} onClick={ReportExpand}>
                            <ArrowBackIcon/>
                            <p>Back</p>
                            </div> : 
                            <>
                                <div onClick={ ReportExpand } style={{ display: 'flex', gap:"4px" }}>
                                <ErrorOutlineIcon />
                                <p>Report</p>
                                </div>
                                {apiData.user_uid === uid && 
                                <div onClick={handleDelete} style={{ display: 'flex', gap:"4px", paddingTop: '8px' }}>
                                    <DeleteIcon htmlColor="red"/>
                                    <p style={{ color:'red' }}>Delete</p>
                                </div>
                                }
                            </>
                            }
                        </div>
                        {reportExpand && 
                            <div className="reportExpand">
                            <span onClick={() => setReportMessage("Spam")}>Spam</span>
                            <hr />
                            <span onClick={() => setReportMessage("Inappropriate")}>Inappropriate</span>
                            <hr />
                            <span onClick={() => setReportMessage("Fake")}>Fake</span>
                            <hr />
                            <span onClick={() => setReportMessage("Abusive")}>Abusive</span>
                            <hr />
                            <span onClick={() => setReportMessage("Hate Speech")}>Hate Speech</span>
                            <hr />
                            <span onClick={() => setReportMessage("Violence")}>Violence</span>
                            <hr />
                            <span onClick={() => setReportMessage("Nudity")}>Nudity</span>
                            <hr />
                            <span onClick={() => setReportMessage("Sexual Content")}>Sexual Content</span>
                            <hr />
                            <span onClick={() => setReportMessage("Harassment")}>Harassment</span>
                            <hr />
                            <span onClick={() => setReportMessage("Threate")}>Threate</span>
                            <hr />
                            <span onClick={() => setReportMessage("Scam")}>Scam</span>
                            <hr />
                            <span onClick={() => setReportMessage("Bullying")}>Bullying</span>
                            <hr />
                            <span onClick={() => setReportMessage("Harmful")}>Harmful</span>
                            <hr />
                            <span onClick={() => setReportMessage("Suicide")}>Suicide</span>
                            <hr />
                            <span onClick={() => setReportMessage("Other")}>Other</span>

                            {reportMessage && 
                                <button disabled={reportButtonDisabled} onClick={handleReport} className="reportButton">Report</button>
                            }
                            </div>
                        }
                        </div>
                        </>
                    )}
                    <MoreVertIcon className="moreIcon" onClick={Report} />
                    </div>
                </div>
                <div className="postCenter">
                    <span className="postText">
                        {showMore ? apiData.title : `${apiData.title ? `${apiData.title?.length > 410 ? `${apiData.title.slice(0, 410) + '...'}` : apiData.title.slice(0, 410)}` : " " }` }
                    <p style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px' }}>
                        <span onClick={showMoreItems} style={{ cursor: "pointer", color: "var(--primary)" }}>
                        {apiData.title?.length < 410 ? " " : `${showMore ? "Show Less" : "Show More"}`} 
                        </span>
                    </p>
                    </span>
                    <img src={apiData.image} loading="lazy" alt="" className="postImg" />
                </div>


                {/* <div onClick={(e) => setShowModal(true)} className="likeDisplay">
                    <span className="postLikeCounter">
                    {likes === 1 ? `${likes} Like` : `${likes} Likes`}
                    </span>
                    <span className="postCommentText">{apiData.comment ? `${apiData.comment} Comment` : '0 Comments'}</span>
                </div> */}

                <div className="postBottom">

                    <div onClick={likeHandler} className="postLike">
                    <FavoriteBorderIcon
                        className={`likeIcon ${isliked ? "liked" : ""}`}
                    />
                    <p style={{ marginTop: '5px'}}>Like</p>
                    </div>

                    {/* <div className="postComment">
                    <ChatBubbleOutlineIcon className="likeIcon" />
                    <p style={{ marginTop: '5px' }}>Comment</p>
                    </div> */}

                    <div onClick={shareButton} className="postShare">
                    <ShareIcon className="likeIcon" />
                    <p style={{ marginTop: '5px' }}>Share</p>
                    </div>

                    {showShare &&
                        <>
                        <div className='shareBoxWrappeŗ' onClick={shareButton}></div>
                        <div className='shareBox'>
                            <p className='shareBoxText'>Share</p>
                            <div className='shareContainer'>
                                <input type="text" className='shareSubBoxOne' value={currentUrl}/>
                                <div onClick={CopyUrl} className='shareBoxButton'>
                                    <ContentCopyIcon/>
                                </div>
                            </div>
                            {showCopied && <p className='shareBoxCopied'>Copied !</p>}
                        </div>
                        </>
                    }

                </div>
                </div>

            </div>

                <div>
                    
                </div>

            </div>
            <Rightbar/>
        </div>
        </div>
        <ToastContainer/>
        </>
    )
}