import React, { useState } from "react";
import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import './businessAcc.css'
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function BusinessAcc () {

    const uid = window.localStorage.getItem("uid"); 

    const[tick, setTick] = useState(false); 

    const okBusiness = () => {
        axios.post('https://ahmedabadsubha.com/api/mobile/user/business/apply', {
            uid: uid
        })
        .then((getData) => {
            // console.log(getData)
            toast.success("Applied Successfully", {
                position: "top-center"
            })

        })
        .catch(e => console.log(e))
    }

    return (
        <>
        <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="badgeWrapper">
                <h3 className="businessAccHeading">Apply For Business Account</h3>

                <div className="businessTermsContainer">

                <h3 className="businessTextHeading">About the Account</h3>
                <p className="businessText">
                Trendings around helps billions of people to connect and share the information with the people they care about.  We offer business account  for the business to grow. Trendings around  Business account allows micro, small, medium and large businesses to communicate with their customers and display their products at a large scale. Using our chat facility, businesses can connect each other as well as with the customers.
                </p>

                <h3 className="businessTextHeading">Requisites</h3>
                <p className="businessText">
                To send messages using Trendings around ‘chat’ you will need a name to be displayed in ‘chat’ seen by customers, a phone number associated with your business account, and a Trendings around Business Account. 
                <br />
                Maintain a Trendings around Business profile with customer support contact information and one or more of the following: email address, website address, and/or telephone number. Keep all information accurate and up-to-date. You must not impersonate another business or otherwise mislead customers as to the nature of your business.
                You may only contact people on Trendings around if: (a) they have given you their mobile phone number; and (b) they have agreed to be contacted by you over Trendings around. Do not confuse, deceive, defraud, mislead, spam, or surprise people with your communications.
                You must respect all requests (either on or off Trendings around) by a person to block, discontinue, or otherwise opt out of communications from you via Trendings around, including removing that person from your contacts list.
                </p>

                <h3 className="businessTextHeading">Phone Number</h3>
                <p className="businessText">
                All Trendings around business accounts rely on a valid phone number. So make sure the number is relevant and available to customers.
                </p>

                <h3 className="businessTextHeading">Display Name</h3>
                <p className="businessText">
                The Display Name is the name of your business that is shown to customers in Trendings around.
                </p>

                <h3 className="businessTextHeading">Payment Methods</h3>
                <p className="businessText">
                Payment can be made by scanning the given QR code available in the app.  For the details on pricing Please refer the Payment details available in each form.
                </p>

                <h3 className="businessTextHeading">Business Policy of  Trendings around is governs your use of Trendings around Business Services as listed below:</h3>
                <p className="businessText">
                • The Trendings around Business account.
                <br />
                • Other Trendings around Business offerings or features that we may provide to you in the future.
                <br />
                <br />
                If you use business account in  Trendings around, or provide any other commercial experiences to sell goods or services, you must comply with the policies and prohibitions listed below, as well as all applicable terms, laws and regulations.
                <br />
                <br />
                1. You are solely responsible for your transactions and providing any sales terms, privacy terms or other terms applicable to your interactions with users.
                We are not responsible for processing, paying for, or fulfilling any sales relating to your transactions.
                <br />
                2. You are solely responsible for determining, collecting, withholding, reporting, and remitting all applicable taxes, duties, fees and additional charges for sales relating to your transactions.
                <br />
                3. Examples listed below are representative, but not exhaustive.
                <br />
                4. The following are prohibited under this policy:    
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Illegal products or services</h3>
                <p className="businessText">
                Businesses may not transact in the operations or exchange of illegal products or services.
                </p>
                
                <h3 style={{ paddingLeft: '20px' }}>Drugs, whether prescription, recreational, or otherwise</h3>
                <p className="businessText">
                Businesses may not transact in the sale of illegal, prescription, or recreational drugs. Manufacturers and healthcare services who do not engage in direct sale are allowed.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Examples:</h3>
                <p className="businessText"> 
                ◦  Drugs, including marijuana and marijuana products <br />
                ◦  Drug paraphernalia, including pipes and bongs <br />
                ◦  Prescription drugs <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Tobacco items and related paraphernalia</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale of tobacco products or tobacco paraphernalia.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Examples:</h3>
                <p className="businessText"> 
                ◦  Apparel featuring a tobacco brand logo <br />
                ◦  Cigarettes, cigars, and chewing tobacco <br />
                ◦  Tobacco pipes and paraphernalia <br />
                ◦  Tobacco rolling machines <br />
                ◦  Hookahs <br />
                ◦  Bongs <br />
                ◦  Rolling papers <br />
                ◦  Electronic cigarettes (E-cigarettes) or tobacco devices
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Alcohol</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale of alcohol.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Books or DVDs about alcohol <br />
                ◦  Alcohol-related items, including glasses, coolers, and wine bottle holders <br />
                ◦  Alcoholic beverages <br />
                ◦  Kits for producing alcohol <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Unsafe ingestible supplements</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale of unsafe ingestible supplements, as determined by Trendings around in its sole discretion.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Anabolic steroids <br />
                ◦  Chitosan  <br />
                ◦  Comfrey <br />
                ◦  Dehydroepiandrosterone <br />
                ◦  Ephedra <br />
                ◦  Human growth hormones <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Weapons, ammunition, or explosives</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale or use of weapons, ammunition, or explosives.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Promoting safety training or licenses for legal weapons <br />
                ◦  Firearms and firearm parts <br />
                ◦  Paintball guns <br />
                ◦  BB Guns <br />
                ◦  Fireworks <br />
                ◦  Pepper spray <br />
                ◦  Tasers <br />
                ◦  Gun ranges <br />
                ◦  Gun shows <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Animals</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale of any animals.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Grooming services <br /> 
                ◦  Boarding services <br />
                ◦  Pet adoption services <br />
                ◦  Live animals <br />
                ◦  Livestock <br />
                ◦  Prohibited animal parts, including but not limited to bone, teeth, horn, ivory, taxidermy, organs, external limbs, secretions, or carcasses <br />
                ◦  Any product or part, including but not limited to leather, skin, hide, fur, wool, or hair from any dogs, cats, and endangered or threatened animals <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Adult Product or Services</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale or use of adult products or services.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Products promoting family planning and contraception, which focus on the contraceptive features of the product, and not on sexual pleasure or sexual enhancement <br />
                ◦  Sex toys <br />
                ◦  Videos or live shows for adult entertainment <br />
                ◦  Sexual enhancement products <br />
                ◦  Sexually suggestive services <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Body parts and fluids</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale of human body parts or fluids.
                </p>
                
                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Blood donation centers <br />
                ◦  Blood <br />
                ◦  Hair extensions and wigs <br />
                ◦  Urine <br />
                ◦  Body parts <br />
                ◦  Organs <br />
                ◦  Human tissue <br />
                ◦  Teeth <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Medical and healthcare products</h3>
                <p className="businessText"> 
                Businesses may not transact in certain healthcare products, including medical devices, and smoking cessation products containing nicotine. Manufacturers and healthcare services who do not engage in direct sale are allowed.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                
                • Medical Devices - Examples: <br />
                    ◦  Lifestyle and fitness accessories <br />
                    ◦  Contact lenses <br />
                    ◦  Bandages and braces for physical injuries <br />
                    ◦  Thermometers <br />
                    ◦  Testing kits for medical conditions or diseases <br />
                    ◦  Breast pumps <br />
                    ◦  First-aid kits <br />
                • Smoking Cessation Products - Examples: <br />
                    ◦  Nicotine patches <br />
                    ◦  Nicotine gum <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Items or products with overtly sexualized positioning</h3>
                <p className="businessText"> 
                Businesses may not position products or services in a sexually suggestive manner.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Implied nudity <br />
                ◦  Implied sexual acts <br />
                ◦  Zoomed-in sexual images <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Real money gambling services</h3>
                <p className="businessText"> 
                Businesses may not transact in or facilitate gambling, games of skill, or lotteries, including online casinos, sports books, bingo, or poker if it costs money.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Dating services</h3>
                <p className="businessText"> 
                Businesses may not transact in or facilitate online dating services.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Products or items that facilitate or encourage unauthorized access to digital media</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale of devices that facilitate or encourage streaming digital content in an unauthorized manner or interfering with the functionality of electronic devices.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Add-on equipment for streaming devices such as keyboards and remotes
                ◦  Sale of streaming devices loaded with software that facilitates unauthorized access to content <br />
                ◦  Jailbroken or loaded devices <br />
                ◦  Jamming or descrambling devices <br />
                ◦  Wiretapping devices <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Digital and subscription services, including links to or processing of any subscription sales, renewals, or upgrades</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale, including renewals and upgrades, etc., of digital content, digital subscriptions, or digital accounts.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Authentic audio or video CDs, DVDs, and Blu Ray <br />
                ◦  Digital devices, including Smartphones, video game consoles, and TVs <br />
                ◦  Downloadable content, including PDFs, music, games, movies, etc. <br />
                ◦  Digital accounts, including games accounts <br />
                ◦  Digital subscriptions and internet streaming services, including TV, Mobile, etc. <br />
                ◦  Digital coupons <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Business models, goods, items, or services that we determine may be or are fraudulent, misleading, offensive, or deceptive, or may be or are exploitative, inappropriate, or exert undue pressure on targeted groups</h3>
                <p className="businessText"> 
                Businesses may not transact in deceptive, false, misleading, or offensive offers or products.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Multilevel marketing <br />
                ◦  Penny auctions <br />
                ◦  ICOs and binary options <br />
                ◦  Payday loans, paycheck advances, P2P lending, debt collection, and bail bonds <br />
                ◦  Diet, weight loss, or other health related products that imply or attempt to generate negative self-perception <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Real, virtual, or fake currency</h3>
                <p className="businessText"> 
                Businesses may not transact in the sale of real, virtual, or fake currency.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                ◦  Real money (cash or cash equivalent instruments and coins) <br />
                ◦  Replica or prop money <br />
                ◦  Digital or cryptocurrency <br />
                ◦  Active bank credit or debit cards <br />
                ◦  Store credit cards or coupons <br />
                ◦  Pre-paid credit or debit cards <br />
                ◦  Checks or checkbooks <br />
                ◦  Equipment to create counterfeit currency or financial instruments <br />
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Third-Party Infringement</h3>
                <p className="businessText"> 
                Transactions may not contain content that infringes upon or violates the intellectual property rights of any third party, including copyright or trademark. This includes, but is not limited to, the sale of counterfeit products, such as goods that copy the trademark (name or logo) and/or distinctive features of another company’s products to imitate a genuine product.
                </p>

                <h3 style={{ paddingLeft: '20px' }}>Exapmles:</h3>
                <p className="businessText"> 
                        ◦  Counterfeits, knockoffs, or replicas of branded goods, or posts offering goods that are likely to confuse consumers about the source, sponsorship or affiliation of those goods.
                        <br />
                        ◦  Unauthorized or pirated copies of copyrighted works, such as videos, movies, TV shows and broadcasts, video games, CDs or other musical works, books, etc.
                        <br />
                These prohibitions apply irrespective of the global or local licenses, registrations, or other approvals your business may hold.
                Organizations must not wrongfully discriminate or suggest a preference for or against people because of a personal characteristic, included but not limited to, race, ethnicity, color, national origin, citizenship, religion, age, sex, sexual orientation, gender identity, family status, marital status, disability, or medical or genetic condition. Organizations must comply with all applicable laws prohibiting discrimination.
                <br />
                Messages must not contain offensive content, for example sexually explicit materials or nudity, determined at our sole discretion.
                Organizations that primarily publish news content must be registered as a new display through trendings  around.
                <br />
                We prohibit organizations and/or individuals engaged in terrorist or organized criminal activity from using the Trendings around Business Services. 
                <br />
                We prohibit the use of the Trendings around Business Services to facilitate or organize criminal activity, including exploiting or causing physical, financial, or other harm to people, businesses, or animals.
                </p>

                <h3 className="businessTextHeading">Business Verification </h3>
                <p className="businessText">
                All businesses initiate a Business Verification when they are ready to scale marketing, utility, and authentication
                </p>

                <h3 className="businessTextHeading">Enforcement & Updates</h3>
                <p className="businessText"> 
                
                • We may limit or remove your access to or use of the Trendings around Business Services if you receive excessive negative feedback, cause harm to Trendings around or our users, or violate or encourage others to violate our terms or policies, as determined by us in our sole discretion. <br />
                • If you use or operate a service which utilizes Trendings around in violation of our terms or policies, such as messaging people at scale in an unauthorized manner, we have the right to limit or remove your access to Trendings around's services. <br />
                • Trendings around may update this Trendings around Business Policy ("Change") without notice (unless such notice is required by law); by continuing to use the Trendings around Business Services after such Change, you consent to such Change. <br />
                • If we terminate your account for violations of relevant Trendings around Business terms or policies, we may prohibit you and your organization from all future use of Trendings around products and services. <br />
                </p>

                <h3 className="businessTextHeading">Protect Data & Comply with Law</h3>
                <p className="businessText"> 
                
                You are responsible for and must secure all necessary notices, permissions, and consents to collect, use, and share people's content and information, including maintaining a published privacy policy, and otherwise complying with applicable law. <br />
                Don't use any data obtained from us about a person you message within Trendings around, other than the content of message threads, for any purpose other than as reasonably necessary to support messaging with that person. <br />
                Don't share or ask people to share full length individual payment card numbers, financial account numbers, personal ID card numbers, or other sensitive identifiers.
                Don’t use Trendings around for telemedicine or to send or request any health information, if applicable regulations prohibit distribution of such information to systems that do not meet heightened requirements to handle health information. <br />
                You may not forward or otherwise share information from a customer chat with any other customer.
                </p>

                <h3 className="businessTextHeading">Opt-In Best Practices</h3>
                <p className="businessText"> 
                
                Driving high quality conversations between people and businesses is a top priority. People can block or report businesses and our systems will rate limit businesses if the business' quality is low for a sustained period of time. We may also reactively evaluate a business' opt-in flows, including review of user feedback, to flag policy violations and develop additional types of enforcement over time.
                </p>

                </div>
            

                <div className="businessAccBottom">
                    <div className="checkboxContainer">
                        <input type="checkbox" className="checkbox" onClick={() => setTick(!tick)}/>
                        <p >I agree to the terms and the conditions.</p>
                    </div>
                    {tick &&
                    <div className="businessAccApply" onClick={okBusiness}>
                        Apply
                    </div>
                    }
                </div>


            </div>
            <Rightbar/>
        </div>
        </div>
        <ToastContainer/>
        </>
    )
}