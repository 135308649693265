import React, { useRef, useState } from 'react'
import './signup.css'

import { useNavigate, Link } from 'react-router-dom'

import { useUserAuth } from '../../context/UserAuthContext'
// import { useDispatch } from 'react-redux'
// import { update } from '../../redux/UserSlice'
import axios from 'axios'

// import { updateProfile } from 'firebase/auth'
import { useSelector } from 'react-redux'

import logo from '../../assets/logo2.png'

export default function Signup() {

  const navigate = useNavigate(); 
  const id = useSelector((state) => state.user); 

    const [values, setValues] = useState({
        name: "", 
        email: "", 
        mobile: "",
        pass: "",
        confPass: "",
        age: "", 
        gender: "", 
        referral: ""
    });

    // console.log(values.name); 

    const [errorMsg, setErrorMsg] = useState(""); 
    const [submitButtonDisable, setSubmitButtonDisable] = useState(false); 

    const { signUp } = useUserAuth(); 
    // const { profileName } = useUserAuth(); 
    // const { user } = useUserAuth; 

    // const dispatch = useDispatch(); 


    const handleSubmission = async (e) => {

        if (values.pass !== values.confPass) {
            setErrorMsg("Password does not match")
            return; 
        }
        setErrorMsg(""); 

        if (!values.name || !values.email || !values.pass) {
            setErrorMsg("Fill all fields"); 
            return; 
        }
        setErrorMsg(""); 

        // setErrorMsg("");
        setSubmitButtonDisable(true); 
        e.preventDefault(); //prevents page not to be refreshed on submit 

        // const name = values.name; 
        // const email = values.email; 
        // dispatch(update({name, email}))

        try {
            
            const uid = await signUp(values.email, values.pass, values.name);
            setSubmitButtonDisable(false); 
            // profileName(values.name); 
            // user.updateProfile({displayName: values.name})
            console.log(uid);

            await axios.post('https://ahmedabadsubha.com/api/mobile/user', { 
                    uid: uid,
                    name: values.name,
                    email: values.email, 
                    mobile: values.mobile,
                    age: values.age, 
                    gender: 'Male', 
                    referal_code: "0082402", 
                    language: [1]
              }, { 
                headers: { 
                    "Content-Type": "application/json"
                }
              })
              .then((res) => { 
                console.log(res.data)
              })

            navigate('/');
        } catch (err) {
            setSubmitButtonDisable(false); 
            setErrorMsg(err.message)
        }
      };

      const inputRef = useRef(); 
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          inputRef.current.blur(); // Remove focus from the input field
          handleSubmission(event);
        }
      }



  return (
    <div className='signup'> 
      <div className="signupWrapper">
        <div className="signupLeft">
            {/* <h3 className="signup-logo">Trendings</h3> */}
            <img src={logo} alt="" className="logo" />
            <span className="signup-description">
                Connect with people and the latest happenings around you on Trendings. 
            </span>
        </div>
        <div className="signupRight">
            <div className="signup-box">

                <div className="signupInput">
                  <input type="text" placeholder='Enter Name' className="innerInput" onChange={e => setValues((prev) => ({...prev, name: e.target.value}))} ref= { inputRef } onKeyDown = {handleKeyDown}/>
                </div>
                <div className="signupInput">
                  <input type="email" placeholder='Enter Email' className="innerInput" onChange={e => setValues((prev) => ({...prev, email: e.target.value}))} ref= { inputRef } onKeyDown = {handleKeyDown}/>
                </div>
                <div className="signupInput">
                  <input type="text" placeholder='Mobile Number' className="innerInput" onChange={e => setValues((prev) => ({...prev, mobile: e.target.value}))} ref= { inputRef } onKeyDown = {handleKeyDown}/>
                </div>
                <div className="signupInput">
                  <input type="password" placeholder='Password' className="innerInput" onChange={e => setValues((prev) => ({...prev, pass: e.target.value}))} ref= { inputRef } onKeyDown = {handleKeyDown}/>
                </div>
                <div className="signupInput">
                  <input type="password" placeholder='Password Again' className="innerInput" onChange={e => setValues((prev) => ({...prev, confPass: e.target.value}))} ref= { inputRef } onKeyDown = {handleKeyDown}/>
                </div>
                <div className="ageGenderContainer">
                  <div className="signupInput signupAgeContainer">
                    <input type="text" placeholder='Age' className="innerInput signupAgeInput" onChange={e => setValues((prev) => ({...prev, age: e.target.value}))} ref= { inputRef } onKeyDown = {handleKeyDown}/>
                  </div>
                  <select className='signupGender' name="" id="">
                          <option value="">Select Gender</option>
                          <option value="">Male</option>
                          <option value="">Female</option>
                  </select>
                </div>

                <select className='signupGender' name="" id="">
                        <option value="">Choose Language</option>
                        <option value="">English</option>
                        <option value="">Hindi</option>
                        <option value="">Punjabi</option>
                        <option value="">Tamil</option>
                        <option value="">Telugu</option>
                        <option value="">Malyalam</option>
                        <option value="">Bengali</option>
                        <option value="">Urdu</option>
                        <option value="">Assamese</option>
                        <option value="">Sind</option>
                        <option value="">Bodo</option>
                        <option value="">Marathi</option>
                        <option value="">Gujarati</option>
                        <option value="">Sanskrit</option>
                        <option value="">Kannada</option>
                        <option value="">Odia</option>
                        <option value="">Konkani</option>
                        <option value="">Maithili</option>
                        <option value="">Dogri</option>
                        <option value="">Kashmiri</option>
                        <option value="">Manipuri</option>
                        <option value="">Santali</option>
                      
                    </select>

                  <div className="signupInput">
                   <input type="text" placeholder='Referral Code' className="innerInput" onChange={e => setValues((prev) => ({...prev, referral: e.target.value}))} ref= { inputRef } onKeyDown = {handleKeyDown}/>
                  </div>

                  {/* <div className="advertiseUsBottom">
                        <div className="advertiseUsTerms">
                            <input type="checkbox" className="checkbox adUsCheck" />
                            <p>I agree to the Privacy Policy, Terms Of Use, Advertisement Policy, Business Policy, Disclaimer and Community Standards. </p>
                        </div>
                  </div> */}

                  <div className='signupCheckboxContainer'>
                    <input type="checkbox" className="checkbox adUsCheck" />
                    <p>I agree to the Privacy Policy, Terms Of Use, Advertisement Policy, Business Policy, Disclaimer and Community Standards. </p>
                  </div>


                 <p className='none'>{errorMsg.slice(10, 999)}</p>
                
                <button className="signup-button" 
                onClick={handleSubmission} 
                disabled={submitButtonDisable}
                >Sign Up</button>
                
                <Link to='/' className='test'>
                    {/* The css for the test classname is done in login.css */}
                    <button className="signup-register">Log into account</button>
                </Link> 
            </div>
        </div>
      </div>
    </div>
  )
}
