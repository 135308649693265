import axios from "axios";
import { useEffect, useState } from "react"
import Trade from "../../components/trade/Trade";
import Classified from "../../components/adsBlock/Classified";
import TradeJobs from "../../components/trade/TradeJobs";
import RecruitDetail from "../recruiter/RecruitDetail"

export default function ProfileBusiness () {

    const [apiData, setApiData] = useState("");  
    const [profileApiData, setProfileApiData] = useState(""); 

    const uid = window.localStorage.getItem("uid"); 
    useEffect(() => {
        axios.get(`https://ahmedabadsubha.com/api/mobile/profile-business/${uid}`)
        .then((res) => {
            setApiData(res.data.data); 
        }) 
        .catch ((e) => {
            console.log(e); 
        })

        axios.get(`https://ahmedabadsubha.com/api/mobile/user/${uid}`)
        .then((getData) => {
        setProfileApiData(getData); 
        // console.log(profileApiData.data.data); 
        })
        .catch(e => {
        console.log(e); 
        })
    }, [uid])

    const [categoryActive, setCategoryActive] = useState("classified"); 
    console.log(categoryActive); 
    
    const classified = () => {
        setCategoryActive("classified");
    }
    const business = () => {
        setCategoryActive("business"); 
    }
    const recruiter = () => {
        setCategoryActive("recruiter"); 
    }
    const seeker = () => {
        setCategoryActive("seeker"); 
    }
    const matrimonial = () => {
        setCategoryActive("matrimonial");
    }

    console.log(apiData); 
    // console.log(apiData.classified_ads)
    const [searchInput, setSearchInput] = useState(""); 
    
    return (
        <>
        <div className="ProfileBusinessCategory">
            <div className="profileBusinessEveryCategoryContainer">
                <p onClick={classified} className={`profileBusinessEveryCategory ${categoryActive === "classified" && "categoryActive"}`}>Classified</p>
            </div>
            <div className="profileBusinessEveryCategoryContainer">
                <p onClick={business} className={`profileBusinessEveryCategory ${categoryActive === "business" && "categoryActive"}`}>Business Listing</p>
            </div>
            <div className="profileBusinessEveryCategoryContainer">
                <p onClick={recruiter} className={`profileBusinessEveryCategory ${categoryActive === "recruiter" && "categoryActive"}`}>Job Recruiter</p>
            </div>
            <div className="profileBusinessEveryCategoryContainer">
                <p onClick={seeker} className={`profileBusinessEveryCategory ${categoryActive === "seeker" && "categoryActive"}`}>Job Seeker</p>
            </div>
            {/* <div className="profileBusinessEveryCategoryContainer">
                <p onClick={matrimonial} className={`profileBusinessEveryCategory ${categoryActive === "matrimonial" && "categoryActive"}`}>Matrimonial</p>
            </div> */}
        </div>

        {categoryActive === 'classified' &&
            <div>
                {apiData?.classified_ads?.length === 0 ? <div className="profileNoAds">No Ads</div> 
                : 
                <div>
                    {apiData?.classified_ads?.map((data) => {
                    return(
                        <Classified key={data.id} post={data} /> 
                    )
                    })}
                </div>
                }
            </div>
        }
        
        {categoryActive === 'business' &&
            <div>
                {apiData?.classified_jobs?.length === 0 ? <div className="profileNoAds">No Ads</div> 
                : 
                <div>
                   {apiData?.classified_jobs.map((data) => {
                    return (
                    <Trade key={data?.id} trade={data}/>
                    )
                })}
                </div>
                }
            </div>
        }
        
        {categoryActive === 'recruiter' &&
            <div>
                {apiData?.job_postings?.length === 0 ? <div className="profileNoAds">No Ads</div> 
                : 
                <div>

                    <div>
                    <input 
                        type="text" 
                        placeholder='Search' 
                        style={{ 
                        margin: '10px 0 8px 0', 
                        borderRadius: '20px', 
                        padding: '0px 10px', 
                        outline: 'none', 
                        fontSize: '14px' 
                        }} 
                        onChange={(e) => setSearchInput(e.target.value)}
                        />
                    </div>

                    <div>
                    {apiData?.job_postings
                    .filter((value) => {
                        if(searchInput === "") {
                        return value; 
                        } else if (
                        value.job_title
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                        ) {
                        return value; 
                        }
                    })
                    .map((data) => {
                        return (
                        <RecruitDetail key={data.id} value={data}/>
                        )
                        })}
                    </div>
                </div>
                }
            </div>
        }

        {categoryActive === 'seeker' &&
            <div>
                {apiData?.job_seekers?.length === 0 ? <div className="profileNoAds">No Ads</div> 
                : 
                <div>

                <div>
                  <input 
                    type="text" 
                    placeholder='Search' 
                    style={{ 
                      margin: '10px 0 8px 0', 
                      borderRadius: '20px', 
                      padding: '0px 10px', 
                      outline: 'none', 
                      fontSize: '14px' 
                    }} 
                    onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>    

                <div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto'}}>
                    {apiData?.job_seekers
                    .filter((value) => {
                    if(searchInput === "") {
                        return value; 
                    } else if (
                        value.name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    ) {
                        return value; 
                    }
                    })
                    .map((data) => {
                    return (
                        <TradeJobs key={data.id} trade={data} job={profileApiData?.data?.data?.jobs}/>
                    )
                    })}
                </div>
                </div>
                }
            </div>
        }


        {/* {categoryActive === 'matrimonial' &&
            <div>
                {apiData?.matrimonial?.length === 0 ? <div className="profileNoAds">No Ads</div> 
                : 
                <div>
                  matri 
                </div>
                }
            </div>
        } */}

        </>
    )
}