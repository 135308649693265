import React, { useEffect, useState } from "react";
import Topbar from "../../components/topbar/Topbar";
import axios from "axios";
import { GLOBAL_URL } from "../../config/constant";
import "./policy.css";
import Footer from "../../components/footer/Footer";

const PrivacyPolicy = () => {
  const [data, setData] = useState("");

  const getPrivacyPolicy = async () => {
    try {
      const res = await axios.get(
        `${GLOBAL_URL}/trendings-policy/privacy-policy`
      );
      setData(res.data.data.content);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <>
      <Topbar />
      <div className="containerForSearchBar"></div>
      <div className="homeContainer policyMainContainer">
        <div className="policy-container">
          <div dangerouslySetInnerHTML={{ __html: data }}></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
