import React, { useEffect, useState } from 'react'
import "./video.css";
// import {Button, Avatar} from "@material-ui/core"
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Ticker from 'react-ticker';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import { Button } from '@mui/material';
import auxImage from '../../assets/qr.jpg'
import axios from 'axios';

import PostModal from '../post/PostModal';
import { ToastContainer, toast } from "react-toastify";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';

function ReelFooter({image, id, likesArr, song, avatarSrc, likes, shares, channel, isLiked, setIsLiked}) {

    const uid = window.localStorage.getItem("uid"); 

    const setLike = async () => {
        setIsLiked(!isLiked);

        try {
            const response = await axios.post('https://ahmedabadsubha.com/api/mobile/reaction/reel', {
                reaction: "like",
                uid: uid, 
                reel_id: id
            });
            // console.log(response); 
        } catch (e) {   
            console.log(e); 
        }
    }

    const [fav, setFav] = useState(false); 
    const reelSave = async () => {
        setFav(!fav); 

        const reelSaveUrl = `https://ahmedabadsubha.com/api/mobile/user/${uid}/save-reel`; 
        const data = {
            reel_id: id, 
            action: fav ? "save" : "unsave", 
        }
        const config = {
            Headers: {
                "Content-Type": "application/json", 
            }, 
        }; 

        try {
            const response = await axios.post(reelSaveUrl, data, config);
            // console.log(response); 
        } catch (e) {
            console.log(e); 
        }
    }; 

    
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    };
    
    console.log(likesArr); 
    const userName = window.localStorage.getItem("name"); 
    // const isNameThere = likesArr.some(obj => obj.name === userName); 

    // useEffect(() => {
    //   if(isNameThere) {
    //     setIsLiked(true); 
    //   } else {
    //     setIsLiked(false)
    //   }
    // }, [])

    const [showReport, setShowReport] = useState(false);
    const Report = () => {
      setShowReport(!showReport);
      setReportExpand(false); 
    };
  
    const [reportExpand, setReportExpand] = useState(false); 
    const ReportExpand = () => {
      setReportExpand(!reportExpand); 
    }

    const [reportMessage, setReportMessage] = useState(""); 
    const [reportButtonDisabled, setReportButtonDisabled] = useState(false); 
  
    const handleReport = async () => {
      setReportButtonDisabled(true)
      await axios.post('https://ahmedabadsubha.com/api/mobile/report/selfie', {
        selfie: id,
        user: uid, 
        reason: reportMessage
      })
      .then (res => {
        console.log(res)
        toast.success("Report Sent", {
          position: "top-center"
        })
      })
      .catch (e => {
        console.log(e)
        setReportButtonDisabled(false); 
      })
    }

    const handleDelete = () => {
    
        axios.delete(`https://ahmedabadsubha.com/api/mobile/selfie/delete/${id}`)
        .then (status => {
          console.log("deleted successfully")
        }) 
        .catch (e => {
          console.log(e)
        })
    
      }


    return (
        <div className="videoFooter">
            <div className="videoFooterBottomContainer">
                <div className='videoProfileContainer'>
                    {image ? 
                    <img src={image} alt="" className='reelVideoProfileImage'/>
                    : 
                    <p className='videoFooterProfileImageReplacement'>
                        {channel?.slice(0, 1).toUpperCase()}
                    </p>
                    }
                    <p style={{ color: 'white' }}>{channel}</p>
                </div>
                <div className="videoFooterButtons">
                    <StarIcon onClick={reelSave} style={fav?{ color: "yellow" }:{ color: 'white' }}/>
                    <FavoriteIcon onClick={setLike} style={isLiked?{color:"red"}:{color:"white"}} />
                    <ModeCommentIcon onClick={() => setShowModal(true)} style={{color:"white"}}/>
                    <ShareIcon style={{color:"white"}}/>
                    <MoreVertIcon onClick={Report}  style={{color:"white"}}/>
                    {showReport && (
                <>
                <div className="reportWrapper" onClick={Report}></div>
                <div className="PostReport">
                  <div>
                    {reportExpand ? <div style={{ display: 'flex', gap: '4px' }} onClick={ReportExpand}>
                      <ArrowBackIcon/>
                      <p>Back</p>
                      </div> : 
                      <>
                        <div onClick={ ReportExpand } style={{ display: 'flex', gap:"4px" }}>
                          <ErrorOutlineIcon />
                          <p>Report</p>
                        </div>
                        {id === uid && 
                          <div onClick={handleDelete} style={{ display: 'flex', gap:"4px", paddingTop: '8px' }}>
                            <DeleteIcon htmlColor="red"/>
                            <p style={{ color:'red' }}>Delete</p>
                          </div>
                        }
                      </>
                    }
                  </div>
                  {reportExpand && 
                    <div className="reportExpand">
                      <span onClick={() => setReportMessage("Spam")}>Spam</span>
                      <hr />
                      <span onClick={() => setReportMessage("Inappropriate")}>Inappropriate</span>
                      <hr />
                      <span onClick={() => setReportMessage("Fake")}>Fake</span>
                      <hr />
                      <span onClick={() => setReportMessage("Abusive")}>Abusive</span>
                      <hr />
                      <span onClick={() => setReportMessage("Hate Speech")}>Hate Speech</span>
                      <hr />
                      <span onClick={() => setReportMessage("Violence")}>Violence</span>
                      <hr />
                      <span onClick={() => setReportMessage("Nudity")}>Nudity</span>
                      <hr />
                      <span onClick={() => setReportMessage("Sexual Content")}>Sexual Content</span>
                      <hr />
                      <span onClick={() => setReportMessage("Harassment")}>Harassment</span>
                      <hr />
                      <span onClick={() => setReportMessage("Threate")}>Threate</span>
                      <hr />
                      <span onClick={() => setReportMessage("Scam")}>Scam</span>
                      <hr />
                      <span onClick={() => setReportMessage("Bullying")}>Bullying</span>
                      <hr />
                      <span onClick={() => setReportMessage("Harmful")}>Harmful</span>
                      <hr />
                      <span onClick={() => setReportMessage("Suicide")}>Suicide</span>
                      <hr />
                      <span onClick={() => setReportMessage("Other")}>Other</span>

                      {reportMessage && 
                        <button disabled={reportButtonDisabled} onClick={handleReport} className="reportButton">Report</button>
                      }
                    </div>
                  }
                </div>
                </>
              )}
                </div>
            </div>
            {showModal && <PostModal likes={likesArr} id={id} closeModal={closeModal} />}
            <ToastContainer/>
        </div>
    )
}

export default ReelFooter
