import React, { useEffect, useState } from 'react'
import './jobs.css'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import BusinessToggle from '../../components/businessToggle/BusinessToggle'
import Rightbar from '../../components/rightbar/Rightbar'

import axios from 'axios'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { useLocation } from 'react-router-dom'

export default function JobDetail(props) {

  const [apiData, setApiData] = useState([]); 

  useEffect(() => {
    axios.get('https://ahmedabadsubha.com/api/mobile/job-seeking')
    .then((getData) => {
      setApiData(getData.data.data)
    })
  }, [])

  // console.log(apiData.name); 

  const location = useLocation(); 

  return (
    <>
    <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="tradeWrapper" >
                <BusinessToggle/>
                <div className='jobDetailContainer'>
                    <div style={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      justifyContent: 'space-evenly',
                      padding: '40px 0 0 0'
                      }}>
                      <img src={location.state.photo} alt="Job Detail Img" className="jobDetailImg" />
                    </div>
                    <div className='jobDetailSubDetail'>

                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <p style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center'}}>{location.state.name}</p>
                        <a className='resumeDownload' href={location.state.resume} style={{ textDecoration: 'none' }}>Download Resume</a>
                      </div>
                      
                      <hr style={{ margin: '10px 0 0 0'}} />

                      <div className='jobDetailBottomContainer'>

                        <div>
                        <div className="jobDetailDesc">
                          <p style={{ width: '200px', color: 'var(--primary)', fontWeight: 'bold'}}>Current Profile : </p>
                          <p>{location.state.profile}</p>
                        </div>
                        <div className="jobDetailDesc">
                          <p style={{ width: '200px', color: 'var(--primary)', fontWeight: 'bold'}}>Educational Qualification : </p>
                          <p>{location.state.education_qualification}</p>
                        </div>
                        <div className="jobDetailDesc">
                          <p style={{ width: '200px', color: 'var(--primary)', fontWeight: 'bold'}}>Work Experience : </p>
                          <p>{location.state.experience}</p>
                        </div>
                        <div className="jobDetailDesc">
                          <p style={{ width: '200px', color: 'var(--primary)', fontWeight: 'bold'}}>Current CTC : </p>
                          <p>{location.state.ctc ? `${location.state.ctc}` : 'N/A'}</p>
                        </div>
                        </div>

                        <div style={{ display: 'flex', marginLeft: 'auto'}}>
                          <div className="jobDetailChat">
                            <ChatBubbleIcon/>
                            Message
                          </div>
                        </div>
                      
                      </div>




                    </div>
                </div>
            </div>
            <Rightbar/>
        </div>
    </div>
    </>
  )
}
