import React from 'react'
import './loader.css'

export default function Loader() {
  return (
    <>
     <div className="loadContainer">
     <div class="loader">
        <div class="box-load1"></div>
        <div class="box-load2"></div>
        <div class="box-load3"></div>
    </div>
     </div>
    </>
  )
}
