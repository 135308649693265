import React from 'react'
import './businessToggle.css'

import { NavLink } from 'react-router-dom'

export default function BusinessToggle() {
  return (
    <>
    <div className='businessToggleContainer'>
        <NavLink className='toggleOptions' activeClassName="businessActive" exact to='/business'>
            <div>
                Business Listings
            </div>
        </NavLink>
        <NavLink className='toggleOptions' to='/business/jobsPreview'>
            <div>
                Jobs
            </div>
        </NavLink>
        {/* <NavLink className='toggleOptions' to='/matrimonial'>
            <div>
                Matrimonial
            </div>
        </NavLink> */}
        
    </div>
    </>
  )
}
