import React from "react";
import "./servicesOffered.css";
import Topbar from "../../components/topbar/Topbar";
import Footer from "../../components/footer/Footer";

const ServicesOffered = () => {
  return (
    <div>
      <Topbar />
      <div className="servicesContainer">
        <p>
          <strong>Services Offered @TA&nbsp;</strong>
        </p>
        <p>
          &lsquo;Trendings Around&rsquo;- a multiutility mobile app- from
          Ahmedbad Subha LLP.&nbsp;&nbsp;Your ultimate mobile application for
          staying connected with the latest news, social media updates, business
          listings, classified ads, and job opportunities. Whether you&apos;re a
          news enthusiast, a social media connoisseur, a business owner, or a
          job seeker, &lsquo;Trendings Around&rsquo; has something for
          everyone.&nbsp;
        </p>
        <p>
          <strong>Social Media Features</strong>
        </p>
        <p>
          Connect with friends, family, and like-minded individuals through
          Trendings Around&apos;s social media capabilities. Share your
          thoughts, experiences, and photos with your network, and engage with
          others through comments and likes. Explore the exciting world of
          Reels, where you can share short, creative videos to express yourself
          and entertain your followers. Stay connected and entertained with our
          integrated chat feature, allowing you to communicate seamlessly with
          your contacts. There is also Play &amp; Win feature to entertain and
          win attractive prizes.
        </p>
        <p>
          <strong>Business Listings and Classified Ads</strong>
        </p>
        <p>
          Looking for a new restaurant to try, or searching for the perfect
          gift? &lsquo;Trendings Around&rsquo; offers a comprehensive business
          listing feature, helping you discover local businesses and services in
          your area. From restaurants and shopping outlets to healthcare and
          entertainment options, you can find it all on &lsquo;Trendings
          Around&rsquo;. Additionally, our classified ads section provides a
          platform for buying and selling goods, as well as advertising various
          services.
        </p>
        <p>
          <strong>Features of Business Listing:</strong>
        </p>
        <p>
          o &nbsp; &nbsp;Any business can be enlisted with TA App. at a minimum
          cost.
        </p>
        <p>o &nbsp; &nbsp;Upload business/product/owner photographs</p>
        <p>o &nbsp; &nbsp;Options to hide contact details&nbsp;</p>
        <p>o &nbsp; &nbsp;Search and filters</p>
        <p>o &nbsp; &nbsp;Business details and category</p>
        <p>o &nbsp; &nbsp;Enhance the business SEO</p>
        <p>o &nbsp; &nbsp;Navigation</p>
        <p>o &nbsp; &nbsp;Review, rating and feedbacks</p>
        <p>
          o &nbsp; &nbsp;Customers can see product pictures, offers and other
          details without registering in the app.
        </p>
        <p>
          o &nbsp; &nbsp;Products offers will be displayed to local customers
          Customers can directly get in touch with the business representative
          via chat after registration.
        </p>
        <p>o &nbsp; &nbsp;Easy pay.</p>
        <p>o &nbsp; &nbsp;Data privacy and security</p>
        <p>
          <strong>Benefits of Business listing:</strong>
        </p>
        <p>
          &bull; &nbsp; &nbsp;Strengthen Your Online Presence in the market.
        </p>
        <p>&bull; &nbsp; &nbsp;Amplify Your Visibility to Local Community.</p>
        <p>
          &bull; &nbsp; &nbsp;Improve the local SEO with Google Maps
          integration.
        </p>
        <p>
          &bull; &nbsp; &nbsp;Build social proof, with customer reviews about
          your business in &lsquo;Trendings Around&rsquo;.
        </p>
        <p>
          &bull; &nbsp; &nbsp;Easy way for your customers to find your physical
          shopfront.
        </p>
        <p>
          &bull; &nbsp; &nbsp;Make sure your online presence in the market with
          &lsquo;Trendings Around&rsquo;.
        </p>
        <p>
          &bull; &nbsp; &nbsp;Anyone can advertise with TA with a minimum cost.
        </p>
        <p>
          &bull; &nbsp; &nbsp; Get Discovered and Hired More Frequently with TA.
        </p>
        <p>&bull; &nbsp; &nbsp; Sales leads to be provided</p>
        <p>&bull; &nbsp; &nbsp;Backend support to promote offers.</p>
        <p>
          <strong>Choose your business plan</strong>
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top">
                <p>
                  <strong>Quarterly Premium Plan:</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Half yearly premium Plan:</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Annual Premium Plan</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p>Plan Amount INR 5999/-</p>

                <p>Validity for three months</p>

                <p>What you get</p>
                <ul>
                  <li>
                    Visibility of business details to public for three months.
                  </li>
                  <li>Option to upload up to 10 business images.</li>
                  <li>
                    Easy accessibility of location with location tagging of
                    business.
                  </li>
                  <li>Get unlimited enquiries</li>
                  <li>
                    Chat option to get in touch with the customers directly.
                  </li>
                  <li>Visibility of detailed business profile to public.</li>
                  <li>Better Chances of Ranking Above Competitors</li>
                  <li>Useful Customer Feedback and reviews.</li>
                  <li>Get one week free poster advertisement within TA.</li>
                  <li>Upload 10 offers</li>
                </ul>
              </td>
              <td valign="top">
                <p>Plan Amount INR 10495/-</p>

                <p>Validity for six months</p>

                <p>What you get</p>
                <ul>
                  <li>
                    Visibility of business details to public for six months.
                  </li>
                  <li>Option to upload up to 10 business images.</li>
                  <li>
                    Easy accessibility of location with location tagging of
                    business.
                  </li>
                  <li>Get unlimited enquiries</li>
                  <li>
                    Chat option to get in touch with the customers directly.
                  </li>
                  <li>Visibility of detailed business profile to public.</li>
                  <li>Better Chances of Ranking Above Competitors</li>
                  <li>Useful Customer Feedback and reviews.</li>
                  <li>Get two weeks free poster advertisement within TA.</li>
                  <li>Upload unlimited offers</li>
                  <li>Premium customer support.</li>
                  <li>High search visibility with SEO .</li>
                </ul>
              </td>
              <td valign="top">
                <p>Plan Amount INR 14999/-</p>

                <p>Validity for one year</p>

                <p>What you get</p>
                <ul>
                  <li>
                    Visibility of business details to public for twelve months.
                  </li>
                  <li>Option to upload up to 10 business images.</li>
                  <li>
                    Easy accessibility of location with location tagging of
                    business.
                  </li>
                  <li>Get unlimited enquiries</li>
                  <li>
                    Chat option to get in touch with the customers directly.
                  </li>
                  <li>Visibility of detailed business profile to public.</li>
                  <li>Better Chances of Ranking Above Competitors</li>
                  <li>Useful Customer Feedback and reviews.</li>
                  <li>Get one month free poster advertisement within TA.</li>
                  <li>Upload unlimited offers</li>
                  <li>Premium customer support.</li>
                  <li>High search visibility with SEO.&nbsp;</li>
                  <li>Support in advt. and catalogue design.</li>
                  <li>Verified tag.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Base listing Business plan</strong>
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top">
                <p>
                  <strong>Monthly base plan</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p>Plan amount INR 499/-</p>

                <p>Validity for one month</p>

                <p>What you get</p>
                <ul>
                  <li>
                    Visibility of&nbsp;&nbsp;business details to public for one
                    month.
                  </li>
                  <li>Option to upload up to three business images.</li>
                  <li>
                    Easy accessibility of location with location tagging of
                    business.
                  </li>
                  <li>Get unlimited enquiries</li>
                  <li>Get two days free poster advertisement within TA.</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Features of classified Ads</strong>
        </p>
        <ul>
          <li>Brief and concise</li>
          <li>Ad categorization</li>
          <li>Option to hide contact details</li>
          <li>Search and filters</li>
          <li>Navigation</li>
          <li>
            Customer can see product pictures and other details without
            registerisng in App.
          </li>
          <li>
            Custosmers can directly get in touch with the concerned individual
            via chat.
          </li>
          <li>Easy Pay</li>
          <li>Data Privacy and Security</li>
        </ul>
        <p>
          <strong>Benefits of classifies Ads</strong>
        </p>
        <ul>
          <li>
            Provides wider Reach, allowing advertisers to target a broader
            audience.
          </li>
          <li>
            Ads are accessible anytime and anywhere with internet access,
            providing convenience for both advertisers and potential buyers.
          </li>
          <li>
            Classifieds Ads are cost effective as compared to business listing,
            its suits to the budget of a common man.&nbsp;
          </li>
          <li>Available for shorter period</li>
          <li>
            IT can be posted and published immediately, eliminating the delays
            in display.
          </li>
          <li>
            Advanced search and filter options based on categories, location,
            etc., making it easier for users to find relevant ads quickly.
          </li>
          <li>Chat Option helps easy communication between the parties.</li>
          <li>Navigation helps to find the location easily.</li>
          <li>
            Ads can be easily edited, updated, or removed as needed, providing
            flexibility.
          </li>
          <li>
            Images, videos, and other multimedia elements to enhance the
            attractiveness and effectiveness of the ad.
          </li>
        </ul>
        <p>
          <strong>Choose your business plan</strong>
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top">
                <p>
                  <strong>Monthly standard plan</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Two months Elite plan</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Quarterly Elite Plan:</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p>Plan amount INR 199/-</p>

                <p>Validity for one month</p>

                <p>What you get</p>
                <ul>
                  <li>
                    Visibility of business/product details to public for one
                    month.
                  </li>
                  <li>Option to upload business images.</li>
                  <li>
                    Easy accessibility of location with location tagging of
                    business.
                  </li>
                  <li>Get unlimited enquiries</li>
                  <li>
                    Chat option to get in touch with the customers directly.
                  </li>
                </ul>
              </td>
              <td valign="top">
                <p>Plan Amount INR 349/-</p>

                <p>Validity for two months</p>

                <p>What you get</p>
                <ul>
                  <li>
                    Visibility of&nbsp;&nbsp;business/product&nbsp;&nbsp;details
                    to public for one month.
                  </li>
                  <li>Option to upload business images.</li>
                  <li>
                    Easy accessibility of location with location tagging of
                    business.
                  </li>
                  <li>Get unlimited enquiries</li>
                  <li>
                    Chat option to get in touch with the customers directly.
                  </li>
                  <li>Possibility to Upload 3 classified advertisements</li>
                </ul>
              </td>
              <td valign="top">
                <p>Plan Amount INR 499/-</p>

                <p>Validity for three months</p>

                <p>What you get</p>
                <ul>
                  <li>
                    Visibility of&nbsp;&nbsp;business/product&nbsp;&nbsp;details
                    to public for one month.
                  </li>
                  <li>Option to upload business images.</li>
                  <li>
                    Easy accessibility of location with location tagging of
                    business.
                  </li>
                  <li>Get unlimited enquiries</li>
                  <li>
                    Chat option to get in touch with the customers directly.
                  </li>
                  <li>Possibility to Upload 6 classified advertisements</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Job Portal</strong>
        </p>
        <p>
          For job seekers and employers alike, &lsquo;Trendings Around&rsquo;
          includes a dedicated job portal where you can browse job listings,
          post job openings, and connect with potential employers or candidates.
          Whether you&apos;re seeking new career opportunities or looking to
          fill a vacant position within your company, our job portal streamlines
          the process and makes it easier to find the perfect match.
        </p>
        <p>
          <strong>Features of Jobs Portal</strong>
        </p>
        <ul>
          <li>AI Enabled portal</li>
          <li>
            Comprehensive database of job openings across various industries and
            sectors.&nbsp;
          </li>
          <li>Search and Filters</li>
          <li>Free Resume Posting</li>
          <li>Detailed Company Profiles</li>
          <li>Easy Communication through chat</li>
          <li>All&nbsp;&nbsp;India data base of job seekers and recruiters</li>
          <li>Privacy and Security</li>
        </ul>
        <p>
          <strong>Benefits of Jobs Portal</strong>
        </p>
        <ul>
          <li>
            Each job post&nbsp;&nbsp;includes details such as job title, company
            name, location, job description etc.
          </li>
          <li>Advanced filtering options&nbsp;</li>
          <li>
            Global or at least national reach, allowing employers to attract a
            larger pool of candidates .
          </li>
          <li>
            More affordable especially considering the potential audience size
            and duration of exposure.
          </li>
          <li>Ensures faster Hiring Process</li>
          <li>
            Mob app allowing users to search and apply for jobs using
            smartphones, providing convenience and accessibility on the go.
          </li>
        </ul>
        <p>
          <strong>Choose&nbsp;&nbsp;your plan&nbsp;</strong>
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top">
                <p>
                  <strong>Monthly base plan</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Quarterly Superior Plan:</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Half yearly Superior Plan:</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Annual Superior Plan</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p>Plan amount INR 998/-</p>

                <p>Validity for one month</p>

                <p>What you get</p>
                <ul>
                  <li>Complete access to the active job seekers.</li>
                  <li>Unlimited inbound chats</li>
                  <li>5 job posting</li>
                  <li>One active job posting.</li>
                  <li>Advance filtering and Key word search options</li>
                </ul>
              </td>
              <td valign="top">
                <p>Plan Amount INR 2598/-</p>

                <p>Validity for three months</p>

                <p>What you get</p>
                <ul>
                  <li>Complete access to the active job seekers.</li>
                  <li>Unlimited inbound chats</li>
                  <li>Unlimited Job posting</li>
                  <li>5 active job posting</li>
                  <li>Advance filtering and Key word search options</li>
                </ul>
              </td>
              <td valign="top">
                <p>Plan Amount INR 4449/-</p>

                <p>Validity for six months</p>

                <p>What you get</p>
                <ul>
                  <li>Complete access to the active job seekers.</li>
                  <li>Unlimited inbound chats</li>
                  <li>Unlimited Job posting</li>
                  <li>Advance filtering and Key word search options</li>
                  <li>
                    A dedicated support team to help and support you throughout
                    your hiring journey
                  </li>
                </ul>
              </td>
              <td valign="top">
                <p>Plan Amount INR 8449/-</p>

                <p>Validity for one year</p>

                <p>What you get</p>
                <ul>
                  <li>Complete access to the active job seekers.</li>
                  <li>Unlimited inbound chats</li>
                  <li>Unlimited Job posting</li>
                  <li>Advance filtering and Key word search options</li>
                  <li>
                    A dedicated support Manager to help you throughout your
                    hiring journey
                  </li>
                  <li>
                    Shortlisted candidates will be provided as per your
                    requirements.
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Advertisement within portal</strong>
        </p>
        <p>
          You can highlight your business by advertising within the TA portal.
          This can be a poster or video. Advertisement position will be decided
          on first come first serve basis.
        </p>
        <p>
          Maximize Your Reach with Our Tailored Advertisement (Poster/ Video)
          Packages!
        </p>
        <p>
          <strong>Choose your plan-</strong>
        </p>
        <table cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td valign="top">
                <p>
                  <strong>Display Advt. (duration)</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Poster Advt. Rate (INR)&nbsp;</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>Video Advt.(per 10 seconds) Rate (INR)&nbsp;</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  <strong>No. of advertisement</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p>Per day (24 hrs.)</p>
              </td>
              <td valign="top">
                <p>399/-&nbsp;</p>
              </td>
              <td valign="top">
                <p>999/-</p>
              </td>
              <td valign="top">
                <p>1</p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p>One week</p>
              </td>
              <td valign="top">
                <p>999/-</p>
              </td>
              <td valign="top">
                <p>4999/-</p>
              </td>
              <td valign="top">
                <p>5</p>
              </td>
            </tr>
            <tr>
              <td valign="top">
                <p>One month</p>
              </td>
              <td valign="top">
                <p>2499/-</p>
              </td>
              <td valign="top">
                <p>14999/-</p>
              </td>
              <td valign="top">
                <p>15</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          *Placement of advertisement will be in any&nbsp;&nbsp;sections of TA.
        </p>
        <p>** Media of advertisement must be provided by the customer.</p>
        <p>
          <strong>News and Information</strong>
        </p>
        <p>
          Stay informed and up-to-date with the latest news and trends from
          around the world. &lsquo;Trendings Around&rsquo; provides a
          comprehensive news feed, covering a wide range of topics including
          current events, entertainment, technology, health, and more. With our
          user-friendly interface, you can easily personalize your news
          preferences and discover the stories that matter most to you.
        </p>
        <p>
          <strong>Special Features:</strong>
        </p>
        <ul>
          <li>Trendings Score</li>
          <li>Verified Tags&nbsp;</li>
          <li>Student Corner&nbsp;</li>
        </ul>
        <p>
          With its diverse range of features, &lsquo;Trendings Around&rsquo; is
          more than just a mobile app &ndash; it&apos;s a comprehensive digital
          ecosystem that caters to your news, social, and business needs. Join
          the &lsquo;Trendings Around&rsquo; community today and experience the
          convenience and excitement of having everything you need at your
          fingertips.
        </p>
        <p>
          Download &lsquo;Trendings Around&rsquo; now and unlock a world of
          possibilities, all within a single, user-friendly app. Stay informed,
          connected, and engaged with &lsquo;Trendings Around&rsquo; &ndash;
          where everything is just a tap away.
        </p>
        <p>
          <strong>
            <em>
              **Currently we are accepting payment through mobile application
              only. For payment of any of the services, kindly download our
              mobile application. **
            </em>
          </strong>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesOffered;
