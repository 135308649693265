import { useState } from 'react'
import './imageSlider.css'

export default function ImageSlider ({slides}) {

    const [currentIndex, setCurrentIndex] = useState(0); 

    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0; 
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex); 
    }

    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1; 
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex); 
    }

    const goToIndex = (slideIndex) => {
        setCurrentIndex(slideIndex); 
    }
    

    const [openModal, setOpenModal] = useState(false); 
    const bigImage = () => {
        setOpenModal(true); 
    }
    
    const closeModal = () => {
        // console.log("heehe")
        setOpenModal(false)
    }

    // const [onlyOne, setOnlyOne] = useState(true); 
    // const hereArray = slides; 
    // if (hereArray?.length === 1) {
    //     setOnlyOne(true); 
    // } else setOnlyOne(false); 




    const sliderStyle = {
        height: "100%", 
        position: "relative"
    }

    const slideStyles = {
        width: "100%",
        height: "100%",
        borderRadius: "10px", 
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url(${slides[currentIndex]})`, 
        cursor: "pointer"
    }

    // const leftArrowStyles = {
    //     position: "absolute", 
    //     top: "50%", 
    //     transform: "translate(0, -50%)", 
    //     left: "4px", 
    //     fontSize: "45px", 
    //     color: "var(--primary)", 
    //     zIndex: 1, 
    //     cursor: "pointer", 

    //     backgroundColor: "white", 
    //     borderRadius: "20px", 
    //     opacity: "0.7"
    // }

    // const rightArrowStyles = {
    //     position: "absolute", 
    //     top: "50%", 
    //     transform: "translate(0, -50%)", 
    //     right: "4px", 
    //     fontSize: "45px", 
    //     color: "var(--primary)", 
    //     zIndex: 1, 
    //     cursor: "pointer", 

    //     backgroundColor: "white", 
    //     borderRadius: "20px", 
    //     opacity: "0.7"
    // }

    const bullet = {
        cursor: "pointer"
    }

    const bulletContainer = {
        display: "flex", 
        gap: "10px", 
        justifyContent: "center", 
        alignItems: "center"
    }

    return(
        <div style={sliderStyle}>
            <div className='leftArrowStyles' onClick={goToPrevious}>&lt;</div>
            <div style={slideStyles} onClick={bigImage}></div>
            <div className='rightArrowStyles' onClick={goToNext}>&gt;</div>
            <div style={bulletContainer}>
                {slides.map((data, slideIndex) => (
                    <div style={bullet} key={slideIndex} onClick={() => goToIndex(slideIndex)}>•</div>
                    ))}
            </div>


            {openModal && 
            <div>
                <div className='businessImageModalWrapper' onClick={closeModal}></div>
                <div className='businessImageModalContainer'>
                    <img src={slides[currentIndex]} alt="img" className='businessModalImage' />
                </div>
            </div>
            }

        </div>
    )
}