import React, { useState, useEffect } from "react";
import "./home.css";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Feed from "../../components/feed/Feed";
import Rightbar from "../../components/rightbar/Rightbar";
import PreLoader from "../../components/preLoader//PreLoader";

import axios from "axios";
import { Helmet } from "react-helmet-async";

// import { Link } from 'react-router-dom'

export default function Home() {
  // const { search } = useLocation();
  // var s = search.split('=')
  // const [showSite, setSite] = useState(s[1])
  // useEffect(() => {
  //   window.location.hash = '#' + s[s.length - 1]
  //   setTimeout(() => {
  //     setSite(true)
  //   }, 3000)// eslint-disable-next-line
  // }, [])

  const [search, setSearch] = useState("");

  const [apiData, setApiData] = useState("");
  let uid = window.localStorage.getItem("uid");

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    axios
      .get(`https://ahmedabadsubha.com/api/mobile/user/${uid}`)
      .then((getData) => {
        setApiData(getData.data.data);
        // console.log(getData.data.data)
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
        // setLoader(false);
      });
  }, []);

  const img = apiData.profile_image;
  window.localStorage.setItem("profile_img", img);

  const top = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      {false ? (
        <PreLoader />
      ) : (
        <>
          <Helmet>
            <title>Trendings Home</title>
            <meta
              name="description"
              content="Indian Made Social Media Platform - Why do you need multiple apps for multiple tasks? Find everything at one place, try Tendings Around."
            />
            <link rel="canonical" href="/home" />
          </Helmet>
          <Topbar ChangeWord={(word) => setSearch(word)} />
          <div className="containerForSearchBar"></div>
          <div className="homeContainer">
            <Sidebar />
            <Feed feedSearch={search} />
            <div className="gotoTopButton" onClick={top}>
              ^
            </div>
            <Rightbar />
          </div>
        </>
      )}
    </div>
  );
}
