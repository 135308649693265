import "./footer.css";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  //   console.log(currentDate)

  return (
    <>
      <div className="mainFooterComponent">
        {/* <p className='logoFooter'>Trendings</p> */}
        <div className="mainFooterSubContainer">
          <p className="footerSubHeading">Follow Us</p>
          <div>
            <a
              href="https://www.linkedin.com/company/trendings-around%C2%AE/about/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon
                className="footerSocialLinks"
                style={{ fontSize: "30px", color: "black" }}
              />
            </a>
            <a
              href="https://www.instagram.com/p/C2T5JKQCsZz/"
              target="_blank"
              rel="noreferrer"
            >
              <InstagramIcon
                className="footerSocialLinks"
                style={{ fontSize: "30px", color: "black" }}
              />
            </a>
          </div>
        </div>

        <div className="mainFooterSubContainer">
          <p className="footerSubHeading">Contact Us</p>
          <div className="footerContactSectionContainer">
            <p className="footerOfficeDetails">
              <span style={{ fontWeight: "500" }}>Email: </span>
              sales@trendingsaround.com
            </p>
            <p className="footerOfficeDetails">
              <span style={{ fontWeight: "500" }}>About: </span>Indian Social
              Media app which is bridging gap between end users and businesses
              to have a smooth connectivity in market.
            </p>
            <p className="footerOfficeDetails">
              <span style={{ fontWeight: "500" }}>Overview: </span>An exclusive
              mobile application with social and business media features, which
              includes news around, play & win, classifieds, business listing,
              jobs & matrimonial services, Feeds, clips and chat facilities for
              all your daily needs. “Trendings around” is a compact user
              friendly mobile application customised for your personal and
              business needs. We have started with some basic features and
              upgrading it day by day to reach max that you can experience.
            </p>
          </div>
          <a
            style={{ textDecoration: "none", color: "black" }}
            href="https://www.sigmatraffic.com?ref=243354"
          >
            Trendings Around
          </a>
        </div>

        <div className="mainFooterSubContainer">
          <p className="footerSubHeading">Links</p>
          <div>
            <Link to="/privacy-policy" className="footerLinks">
              Privacy Policy
            </Link>
            <br />
            <Link to="/terms-of-use" className="footerLinks">
              Terms Of Use
            </Link>
            <br />
            <Link to="/community-standards" className="footerLinks">
              Community Standards
            </Link>
            <br />
            <Link to="/business-policy" className="footerLinks">
              Business Policy
            </Link>
            <br />
            <Link to="/disclaimer" className="footerLinks">
              Disclaimer
            </Link>
            <br />
            <Link to="/advertisement-policy" className="footerLinks">
              Advertisement Policy
            </Link>
            <br />
            <Link to="/refund-and-cancellation-policy" className="footerLinks">
              Refund/Cancellation
            </Link>
          </div>
        </div>

        <div className="mainFooterSubContainer">
          <p className="footerSubHeading">Contact Us</p>
          <p className="footerOfficeDetails">
            If you have any query or suggestion, please mail us at:
            <br />
            <a
              href="mailto:sales@trendingsaround.com"
              style={{ fontWeight: "500" }}
            >
              sales@trendingsaround.com
            </a>
          </p>
        </div>
      </div>

      <div className="footerBottomContainer">
        <p className="footerLogo">
          <span className="footerLogoFirst">Trendings</span>Around ©{" "}
          {currentYear}
        </p>
      </div>
    </>
  );
}
