import React, { useState, useEffect } from 'react'

import TopbarNews from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import './reels.css'
import Video from '../../components/reelVideo/Video'
import axios from 'axios'

export default function Reels() {

  const[ apiData, setApiData ] = useState([]); 

  useEffect(() => { 
    axios.get('https://ahmedabadsubha.com/api/mobile/reels')
    .then((getData) => { 
      setApiData(getData.data.results)
    })
    .catch(e => console.log(e)); 
  }, [])
  
  console.log(apiData); 
  // console.log(apiData?.likes); 

  return (
    <div>
      <TopbarNews />

      <div className="homeContainer">

        <Sidebar/>

        <div className="blogWrapper">
          <center>
            
            <div className='videoContainer'>
            {apiData.map((reel) => { 
              return (
                <Video 
                key={reel.id}
                name={reel?.user?.name}
                image={reel?.user?.profile_image}
                id={reel?.id}
                likesArr={reel?.likes}

                channel={reel.channel}
                avatarSrc={reel.avatarSrc}
                song={reel.song}
                url={reel.video}
                likes={reel.likes}
                shares={reel.shares}
                />
              )
            })}

            <Video/>
            </div> 
          </center>
        </div>

        <Rightbar/>
        
      </div>
    </div>
  )
}
