import React, { useEffect, useState } from 'react'
import './business.css'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'
import Trade from '../../components/trade/Trade'
import axios from 'axios'
import BusinessToggle from '../../components/businessToggle/BusinessToggle'
import Loader from '../../loader/Loader'

export default function Business() {

  const [apiData, setApiData] = useState([]); 
  const [dataLoading, setDataLoading] = useState(true); 
  const [page, setPage] = useState(1); 
  const [loading, setLoading] = useState(true); 
  
  const callData = async () => {
    try {
      const response = await axios.get(`https://ahmedabadsubha.com/api/mobile/classified-jobs?page=${page}`); 
      console.log("response --> ", response); 
      setApiData(PrevData => [...PrevData, ...response.data.results]); 
      setLoading(false);
    } catch (e) {
      console.log(e); 
    }
  }

  console.log(apiData); 
  
  useEffect(() => {
    callData(); 
  }, [page]); 

  const loadMore = () => {
    setLoading(true); 
    setPage(page + 1); 
  }
  
  
  return (
    <>
    <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="tradeWrapper">
                <BusinessToggle/>
                {/* <Trade/> */}
                {apiData.map((data) => {
                  return (
                    <Trade key={data?.id} trade={data}/>
                  )
                })}

                {loading && <Loader/>}

                <p onClick={loadMore} style={{
                   backgroundColor: 'var(--primary)',
                   color: 'white',
                   padding: '5px 8px',
                   width: 'fit-content',
                   borderRadius: '10px', 
                   margin: '10px auto',
                   cursor: 'pointer'
                }}>Load More</p>
            </div>
            <Rightbar/>
        </div>
    </div>
    </>
  )
}
