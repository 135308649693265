import React, { useState } from "react";
import './aroundToggle.css'
import { NavLink, useNavigate } from 'react-router-dom'
import {newsToggleActive} from '../../redux/UserSlice'
import { useDispatch, useSelector } from "react-redux";


export default function AroundToggle () {

    const navigate = useNavigate(); 

    const reduxState = useSelector(state => state.user); 
    console.log(reduxState.newsToggleActive)


    const [active, setActive] = useState(false); 
    const news = () => {
        setActive(false);
        navigate('/news');
    }
    const quiz = () => {
        setActive(true); 
        navigate('/quiz');
    }

    const dispatch = useDispatch(); 
    dispatch(newsToggleActive({active}))

    return(
        <>
        <div className='businessToggleContainer'>
            <div onClick={news} className={`${active ? '' : 'aroundActive'} toggleOptions`} >
                News
            </div>
            <div onClick={quiz} className={`${active && 'aroundActive' } toggleOptions`}>
                Play & Earn
            </div>
            
        </div>
        </>
    )
}