import React, { useState } from 'react'
import axios from 'axios';
import BusinessIcon from '@mui/icons-material/Business';
import FilterIcon from '@mui/icons-material/Filter';

export default function AdvertiseUsAlt() {

    const [stripName, setStripName] = useState(""); 
    const [stripEmail, setStripEmail] = useState(""); 
    const [stripPhone, setStripPhone] = useState(""); 
    const [stripAddress, setStripAddress] = useState(""); 
    const [stripWebsite, setStripWebsite] = useState(""); 
    const [stripGst, setStripGst] = useState(""); 

    const submitStrip = async () => {

        const formData = new FormData(); 
    
        formData.append("name", stripName)
        formData.append("email", stripEmail)
        formData.append("phone", stripPhone)
        formData.append("address", stripAddress)
        formData.append("website", stripWebsite)
        formData.append("gst_number", stripGst)
        
        try {
            const response = await axios.post(`https://ahmedabadsubha.com/api/mobile/vigyapan/request`, formData, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            console.log(response); 
        } catch (e) {
            console.log(e)
        }
    }

  return (
    <>
    <form action="" onSubmit={submitStrip}>

        <input 
            className='styleInput'
            type="text"
            name='name'
            placeholder='Your Name'
            value={stripName}
            onChange={ (e) => setStripName(e.target.value) } 
            />

            <input 
            className='styleInput'
            type="email"
            name='email'
            placeholder='Your Email'
            value={stripEmail}
            onChange={ (e) => setStripEmail(e.target.value) } 
            />

            <input 
            className='styleInput'
            type="number"
            name='contact'
            placeholder='Your Contact'
            value={stripPhone}
            onChange={ (e) => setStripPhone(e.target.value) } 
                />

            <input 
            className='styleInput'
            type="text"
            name='text'
            placeholder='Your Address'
            value={stripAddress}
            onChange={ (e) => setStripAddress(e.target.value) } 
            />

            <input 
            className='styleInput'
            type="text"
            name='text'
            placeholder='Website'
            value={stripWebsite}
            onChange={ (e) => setStripWebsite(e.target.value) } 
            />  

            <input 
            className='styleInput'
            type="text"
            name='text'
            placeholder='GST Number'
            value={stripGst}
            onChange={ (e) => setStripGst(e.target.value) } 
            />  

            <label htmlFor="file">
                <div className="advertiseUsContainer">
                    <BusinessIcon/>
                    <p className="advertiseUsText">Upload Logo</p>
                </div>
                <input type="file" id="file" className="advertiseUsInput" />
            </label>
            <label htmlFor="file">
                <div className="advertiseUsContainer" style={{ margin: '10px 0 20px 0'}}>
                    <FilterIcon/>
                    <p className="advertiseUsText">Upload Ad Image</p>
                </div>
                <input type="file" id="file" className="advertiseUsInput" />
            </label>

            <input 
            className='styleInput'
            type="number"
            name='contact'
            placeholder='Referral Code (Optional)'
            // value={contact}
                />

            <div className="advertiseUsBottom">
                <div className="advertiseUsTerms">
                    <input type="checkbox" className="checkbox adUsCheck" />
                    <p>I agree to the Privacy Policy, Terms Of Use, Advertisement Policy, Business Policy, Disclaimer and Community Standards. </p>
                </div>
                {/* <input type="submit" value='save' className='formSave' /> */}
                <button type='submit'>submit</button>
            </div>


    </form>

    <button onClick={submitStrip}>hehhe</button>
    </>
  )
}
