import React, { useRef, useState } from 'react'
import './video.css'

import vid01 from  '../../VideoMedia/vid01.mp4'
// import vid02 from '../../VideoMedia/vid02'

import ReelHeader from './ReelHeader';
import ReelFooter from './ReelFooter'

export default function Video({url, name, image, id, likesArr, likes, shares, channel, avatarSrc, song}) {

  const [isLiked, setIsLiked] = useState(false);
  const setLike = () => {
      setIsLiked(!isLiked);
  }

//   console.log(likesArr)


  return (
      <div className="videoCard"> 
          <ReelHeader />
          <video 
              className="video__player" 
              src={url} 
              alt="ig-reel-video" 
              loop
              autoPlay
              playsInline
              muted
              onDoubleClick={setLike}
          />
          <ReelFooter
              channel={name}
              image={image}
              id={id}
              likesArr={likesArr}

              likes={likes}
              shares={shares}
              avatarSrc={avatarSrc}
              song={song}
              isLiked={isLiked}
              setIsLiked={setIsLiked}
          />
      </div>
  )
}
