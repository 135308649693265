import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import image from '../../assets/underConstruction.jpg'
import axios from 'axios'

const name = window.localStorage.getItem("name")
const email = window.localStorage.getItem("email")

const ProfileEdit = () => {

    const uid = window.localStorage.getItem("uid"); 
    const updateProfile = async () => {
        await axios.put(`https://ahmedabadsubha.com/api/mobile/user/${uid}`, {

        })
        .then((getData) => {
            console.log(getData); 
        })
        .catch(e => console.log(e))
    }

    return (
        <>
        <div>
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="badgeWrapper">
                <div className="editProfileTopContainer">
                    <p className='editProfileName' >{name}</p>
                    <p style={{ textAlign: 'center' }}>{email}</p>
                </div>

                <div className='construction'>
                    <img className='profileUnderConstruction' src={image} alt="under construction" />
                </div>

                {/* <div className="editProfileFieldContainer">
                    
                </div> */}


                
            </div>
            <Rightbar/>
        </div>
    </div>
        </>
    )
}

export default ProfileEdit; 