import { useState } from 'react';
import '../profile/profile.css'
import './otherProfile.css'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from "react-redux";
import axios from 'axios'

import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockIcon from '@mui/icons-material/Block';

import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import './otherProfile.css'
import { useLocation } from 'react-router-dom';

export default function OtherProfile (props) {

    const [req, setReq] = useState('Follow')
    const [active, setActive] = useState(false)
    const request = () => {
        setReq('Request Sent')
        setActive(true); 

        toast.success("Request Sent Successfully", {
            position: "top-center"
        }); 
    }

    const location = useLocation(); 
    // console.log("this is from location.state -> " + location.state.uid)

    const [block, setBlock] = useState(false) ;
    const blockOpen = () => {
        setBlock(!block); 
    }

    const uid = window.localStorage.getItem("uid"); 
    const Block = async () => {
        await axios.post('https://ahmedabadsubha.com/api/mobile/user/block/user', {
            uid: uid, 
            blocked_user_uid: location.state.uid
        })
        .then(res => {
            console.log(res); 
            toast.success("User Blocked Successfully", {
                position: "top-center"
            }); 
        })
        .catch(e => {
            console.log(e); 
            toast.error("Something Went Wrong", {
                position: "top-center"
            }); 
        })

    }


    return (
        <>
        <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="badgeWrapper">
                <div className="profileWrapper">
                    <div className="profileInfo">
                        <div className="profileImgContainer">
                            <img src={location.state.profileImage} alt="profile" className="profileImg" />
                            <div className="infoContainer">

                                <div className="otherNameVerify">
                                    <p className="profileName">{location.state.name}</p>
                                    <div onClick={blockOpen} className="otherProfileDots">
                                        <MoreVertIcon/>
                                        {block && 
                                        <>
                                        <div className="reportWrapper" onClick={blockOpen}></div>
                                        <div onClick={Block} className="blockButton">
                                            <BlockIcon htmlColor='red'/>
                                            <p>Block User</p>
                                        </div>
                                        </>
                                        }
                                    </div>
                                </div>

                                <div className="profileVerified">
                                    <p>Verified</p>
                                    <CheckCircleIcon htmlColor="#0066b4"/>
                                </div>

                                <p>Some Hobbies</p>
                                <p>Trendings Score : <span style={{ color: '#0066b4' }}>100</span></p>
                            </div>
                        </div>
                        <div className="profileInfoTop">

                            <div className="statsContainer">
                                <div className="statsDataContainer">
                                    <div>
                                        <p className="profileOne">10</p>
                                        <p className="profileTwo">Posts</p>
                                    </div>
                                    <div>
                                        <p className="profileOne">{""}</p>
                                        <p className="profileTwo">Followers</p>
                                    </div>
                                    <div>
                                        <p className="profileOne">{""}</p>
                                        <p className="profileTwo">Following</p>
                                    </div>
                                </div>
                                <hr />
                                <div className="profileStatusBarContainer">
                                    <p style={{ padding: '0 0 10px 0'}}>(20) Number of Accounts Reached</p>
                                    <div className="profileStatusBar">
                                        <div className="item1 statusItem"></div>
                                        <div className="item2 statusItem"></div>
                                        <div className="item3 statusItem"></div>
                                    </div>
                                    <div className="statusExplain">
                                        <div className="statusExplainContainer">
                                            <div className="item1Explain"></div>
                                            <p>Posts</p>
                                        </div>
                                        <div className="statusExplainContainer">
                                            <div className="item2Explain"></div>
                                            <p>Comments</p>
                                        </div>
                                        <div className="statusExplainContainer">
                                            <div className="item3Explain"></div>
                                            <p>Likes</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="profileMessage">
                            <p className={`request ${active && 'reqActive'}`} onClick={request}>{req}</p>
                            <p className="request">Message</p>
                        </div>
                    </div>
                    {/* <div className="profileSecondContainer">
                        <div className="profileSubInfo">
                            {'apiData.gender' === 'Male' ? <>
                            <MaleIcon/>
                            <p>{'apiData.gender'}</p>
                            </> : <>
                            <FemaleIcon/>
                            <p>{'apiData.gender'}</p>
                            </>}
                        </div>
                        <div className="profileSubInfo">
                            <CakeOutlinedIcon/>
                            <p>{'apiData.age'} Years Old</p>
                        </div>
                    </div> */}
                </div>
            </div>
            <Rightbar/>
        </div>
    </div>
    <ToastContainer/>
        </>
    )
}