import { useState } from 'react'
import './trade.css'

import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';

export default function ReviewModal ({ review, ad_id, closeModal}) {

    const uid = window.localStorage.getItem("uid"); 

    const [reviewText, setReviewText] = useState(""); 
    const [rating, setRating] = useState(0); 
    const [openReviewError, setOpenReviewError] = useState(false); 

    const renderStars = () => {
        const stars = []; 
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <StarIcon
                    key={i}
                    onClick={() => setRating(i)}
                    style={{ color: i <= rating ? 'gold' : 'gray' }}
                />
            )
        }
        return stars; 
    }
    
    const addReview = async () => {
        await axios.post('https://ahmedabadsubha.com/api/mobile/classified-jobs/add-review', {
            uid: uid, 
            ad_id: ad_id, 
            comment: reviewText, 
            rating: rating
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then((data) => { 
            // console.log(data);
        })
        .catch(e => {
            console.log(e)
            // console.log(e.response.data.errors.message)
            if(e.response.data.errors.message === "Already reviewed") {
                setOpenReviewError(true); 
                setTimeout(() => {
                    setOpenReviewError(false); 
                }, 2000);
            }
        })
    }


    return (
    <>
      <div className="review-modal-wrapper" onClick={closeModal}></div>
      <div className="review-modal-container">
        <p className='reviewHeading'>Reviews</p>

        <div className='reviewContainer'>
            {review.map((data) => (
                <>
                <div className="particularReview" key={data.id}>
                    <img src={data.user.profile_image} alt="img" className='reviewImg' />
                    <div>
                        <p style={{}}>{data.user.name}</p>
                        <p style={{ fontSize: "13px", color: "rgb(100, 100, 100)" }}>{data.comment}</p>
                    </div>
                    <div style={{ position: "absolute", right: "5px", display: "flex", gap: "4px", alignItems: "center" }}>
                        <StarIcon style={{ color: "gray" }}/>
                        <p style={{ color: "rgb(100, 100, 100)" }}>{data.rating}</p>
                    </div>
                </div>
                <hr style={{ marginBottom: "3px" }}/>
                </>
            ))}
        </div>

        <div className='reviewBottomContainer'>
            <div className='reviewRatingContainer'>
                {renderStars()}
            </div>
            <div className='reviewInputContainer'>
                <input type="text" placeholder='Review' value={reviewText} onChange={(e) => setReviewText(e.target.value)} className='reviewInputBox'/>
                <button onClick={addReview} className='reviewSubmitButton' style={{ backgroundColor: reviewText === "" ? "gray" : "var(--primary)" }}>Submit</button>
            </div>
        </div>
      </div>
      {openReviewError &&
        <p className={`reviewError ${openReviewError ? "show" : ""}`}>You have already reviewed this article.</p>
      }
    </>
    )
}