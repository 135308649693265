import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import './paymentHis.css'
import { useEffect, useState } from 'react'
import axios from 'axios'

const PaymentHis = () => {

    const [apiData, setApiData] = useState(""); 
    const [errorMsg, setErrorMsg] = useState(""); 

    const uid = window.localStorage.getItem("uid"); 
    useEffect(() => {
        axios.get(`https://ahmedabadsubha.com/api/mobile/payment/user/${uid}`)
        .then((res) => {
            setApiData(res.data.data); 
            console.log(apiData); 
        })
        .catch (e => {
            console.log(e); 
            setErrorMsg(e); 
            // console.log("this is console log of the error message" , errorMsg); 
        })
    }, [])

    var today = new Date();
    console.log(today)
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    // today = mm + '/' + dd + '/' + yyyy;
    console.log(yyyy + '-' + mm + '-' + dd); 

    return (
        <>
        <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="badgeWrapper">
               <div className="paymentHisContainer">
                <p style={{ color: 'var(--primary)', fontSize: '16px', fontWeight: '450' }}>No Payment History</p>
               </div>
            </div>
            <Rightbar/>
        </div>
    </div>
        </>
    )
}

export default PaymentHis; 