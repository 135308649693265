import React, { useEffect, useState } from "react";

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'
import Loader from '../../loader/Loader'

import './profile.css'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector } from "react-redux";
import axios from 'axios'

import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import TheatersIcon from '@mui/icons-material/Theaters';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MovieIcon from '@mui/icons-material/Movie';
import HouseIcon from '@mui/icons-material/House';

import ImageModal from "./ImageModal";
import { useNavigate } from "react-router-dom";
import ProfilePost from "./ProfilePost";
import ProfileBusiness from "./profileBusiness";

export default function ProfileNew () {

    const user = useSelector(state => state.user); 
    const [loading, setLoading] = useState(true); 

    const [req, setReq] = useState('Send Request')
    const [active, setActive] = useState(false)
    const request = () => {
        setReq('Request Sent')
        setActive(true); 
    }

    const name = window.localStorage.getItem("name"); 

    const [apiData, setApiData] = useState(""); 
    let uid = window.localStorage.getItem("uid"); 
    // console.log(uid)
    
    useEffect(() => {
        axios.get(`https://ahmedabadsubha.com/api/mobile/user/${uid}`)
        .then ((getData) => {
            setApiData(getData.data.data)
            setLoading(false)
            console.log( apiData )
        })
        .catch(e => {
            console.log(e)
        })
    }, [])

    console.log(apiData)


    const [file, setFile] = useState(null); 

    const updateProfileImgButton = () => {
        // console.log('button clicked')
        console.log(file[0].name)
        updateProfileImg(file[0].name)
    }

    const updateProfileImg = async () => {
        console.log("this is file" + file)
        const formData = new FormData(); 
        formData.append("profile-file", {
            name: "profile." + file.split(".").pop(),
            type: "image/*", 
            uri: file
        }); 
        await axios.post(`https://ahmedabadsubha.com/api/mobile/user/${uid}/profile-image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }, 
        })
        .then((res) => {
            console.log(res.data); 
        })
        .catch ((e) => {
            console.log(e)
        })
    }

    const closeModal = () => { 
        setFile(null);   
    }

    console.log(name.slice(0, 1).toUpperCase())

    const navigate = useNavigate(); 
    const edit = () => {
        navigate('/editprofile')
    }

    
    const [homeActive, setHomeActive] = useState("home")
    const toggleHome = () => {
        setHomeActive("home"); 
    }

    const toggleMovie = () => {
        setHomeActive("movie")
    }

    const toggleBusiness = () => {
        setHomeActive("buss")
    }

    const toggleStar = () => {
        setHomeActive("star")
    }


    var accountsSum = apiData?.chart_data?.reduce(function (acc, curr) {
        return acc + curr; 
    }, 0); 


    return (
        <>
        <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="badgeWrapper">
                {loading ? <Loader/>
                : 

                <div className="profileWrapper">
                    <div className="profileInfo">
                        <div className="profileImgContainer">
                            <div className="profileImg">
                                {apiData.profile_image ? <>
                                    <img src={apiData.profile_image} alt="profile" className="profileImgTag" />
                                </> : 
                                <>
                                <div className="profileImgAlt"> 
                                    <p className="profileNameLetter">{name.slice(0, 1).toUpperCase()}</p>
                                </div>
                                </>}
                            </div>
                            <div className="infoContainer">

                                <div className="nameVerify">
                                    <p className="profileName">{name}</p>
                                    <div className="profileVerified">
                                        <p>Verified</p>
                                        <CheckCircleIcon htmlColor="var(--primary)"/>
                                    </div>
                                </div>

                                <p>Some Hobbies</p>
                                <p>Trendings Score : <span style={{ color: 'var(--primary)' }}>100</span></p>
                            </div>
                        </div>

                        <div className="profileEdit">
                            <label htmlFor="file">
                                <div>
                                    <p className="picEdit picEditButton">Edit Profile Picture</p>
                                    <input type="file" multiple id='file' accept='.png, .jpeg, .jpg' style={{display: "none"}} onChange={ (e) => setFile(Array.from(e.target.files[0])) } />
                                </div>
                            </label>
                            <p onClick={edit} className="picEdit infoEditButton">Edit Profile</p>
                        </div>
                        {file &&
                            <ImageModal closeModal={closeModal} updateProfileImg={updateProfileImgButton} file={file}/>
                        }

                        <div className="profileInfoTop">

                            <div className="statsContainer">
                                <div className="statsDataContainer">
                                    <div>
                                        <p className="profileOne">10</p>
                                        <p className="profileTwo">Posts</p>
                                    </div>
                                    <div>
                                        <p className="profileOne">{apiData?.followers?.length}</p>
                                        {/* apiData.followers.length */}
                                        <p className="profileTwo">Followers</p>
                                    </div>
                                    <div>
                                        <p className="profileOne">{apiData?.following?.length}</p>
                                        {/* apiData.following.length */}
                                        <p className="profileTwo">Following</p>
                                    </div>
                                </div>
                                {apiData?.chart_data &&
                                <>
                                <hr />
                                <div className="profileStatusBarContainer">
                                    <p style={{ padding: '0 0 10px 0'}}>({accountsSum && accountsSum}) Number of Accounts Reached</p>
                                    <div className="profileStatusBar">
                                        <div className="item1 statusItem" style={{ width: `${((apiData?.chart_data[0]/accountsSum) * 100)}%`}}></div>
                                        <div className="item2 statusItem" style={{ width: `${((apiData?.chart_data[1]/accountsSum) * 100)}%`}}></div>
                                        <div className="item3 statusItem" style={{ width: `${((apiData?.chart_data[2]/accountsSum) * 100)}%`}}></div>
                                    </div>
                                    <div className="statusExplain">
                                        <div className="statusExplainContainer">
                                            <div className="item1Explain"></div>
                                            <p>Posts ({apiData?.chart_data[0]})</p>
                                        </div>
                                        <div className="statusExplainContainer">
                                            <div className="item2Explain"></div>
                                            <p>Comments ({apiData?.chart_data[1]})</p>
                                        </div>
                                        <div className="statusExplainContainer">
                                            <div className="item3Explain"></div>
                                            <p>Likes ({apiData?.chart_data[2]})</p>
                                        </div>
                                    </div>
                                </div>
                                </>
                                }
                            </div>
                        </div>
                        <div className="profileMessage">
                            {/* <p className={`request ${active && 'reqActive'}`} onClick={request}>{req}</p> */}
                            {/* <p className="request">Message</p> */}
                        </div>
                    </div>
                    <div className="profileSecondContainer">
                        <div className="profileSubInfo">
                            <PhoneIcon/>
                            <p>{apiData.mobile}</p>
                        </div>
                        <div className="profileSubInfo">
                            {apiData.gender === 'Male' ? <>
                            <MaleIcon/>
                            <p>{apiData.gender}</p>
                            </> : <>
                            <FemaleIcon/>
                            <p>{apiData.gender}</p>
                            </>}
                        </div>
                        <div className="profileSubInfo">
                            <CakeOutlinedIcon/>
                            <p>{apiData.age} Years Old</p>
                        </div>
                        <div className="profileSubInfo">
                            <PersonOutlineOutlinedIcon/>
                            <p>{apiData.activities}</p>
                        </div>
                        <div className="profileSubInfo">
                            <GroupsIcon/>
                            <TwitterIcon htmlColor="var(--primary)"/>
                            <InstagramIcon htmlColor="var(--primary)"/>
                            <LinkedInIcon htmlColor="var(--primary)"/>
                            <FacebookIcon htmlColor="var(--primary)"/>
                            {/* <p>{apiData.social_network.join(', ')}</p> */}
                        </div>
                        <div className="profileSubInfo">
                            <LocationOnIcon/>
                            <p>{apiData.place_of_living}</p>
                        </div>
                        <div className="profileSubInfo">
                            <TheatersIcon/>
                            <p>{apiData.movies}</p>
                        </div>
                        <div className="profileSubInfo">
                            <FastfoodIcon/>
                            <p>{apiData.food}</p>
                        </div>
                    </div>

                    <div className="profileToggle"> 
                        <div onClick={toggleHome}>
                            <HouseIcon htmlColor={homeActive === 'home' ? "var(--primary)" : ""} fontSize="large" className="homeToggleIcons"/>
                        </div>
                        <div onClick={toggleMovie}>
                            <MovieIcon htmlColor={homeActive === 'movie' ? "var(--primary)" : ""} fontSize="large" className="homeToggleIcons"/>
                        </div>
                        <div onClick={toggleBusiness}>
                            <BusinessCenterIcon htmlColor={homeActive === 'buss' ? "var(--primary)" : ""} fontSize="large" className="homeToggleIcons"/>
                        </div>
                        <div onClick={toggleStar}>
                            <StarBorderOutlinedIcon htmlColor={homeActive === 'star' ? "var(--primary)" : ""} fontSize="large" className="homeToggleIcons"/>
                        </div>
                    </div>

                    {homeActive === 'home' && 
                    <>
                    <ProfilePost/>
                    </>
                    }

                    {homeActive === 'buss' && 
                    <>
                    <ProfileBusiness/>
                    </>
                    }
                </div>
                }
            </div>
            <Rightbar/>
        </div>
    </div>
        </>
    )
}