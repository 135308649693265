import React, { useEffect, useState } from 'react'
import './jobs.css'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import BusinessToggle from '../../components/businessToggle/BusinessToggle'
import Rightbar from '../../components/rightbar/Rightbar'

import axios from 'axios'
import TradeJobs from '../../components/trade/TradeJobs'

import image from '../../assets/underConstruction.jpg'
import LockIcon from '@mui/icons-material/Lock';
import Loader from '../../loader/Loader'

export default function Jobs() {

  const [profileApiData, setProfileApiData] = useState(""); 
  const [apiData, setApiData] = useState([]); 
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true); 

  const uid = window.localStorage.getItem("uid"); 
  
  useEffect(() => {
    axios.get(`https://ahmedabadsubha.com/api/mobile/job-seeking?page=${page}`)
    .then((getData) => {
      setApiData((prevData) => [...prevData, ...getData.data.results]); 
      setLoading(false); 
      // console.log(getData); 
    })

    axios.get(`https://ahmedabadsubha.com/api/mobile/user/${uid}`)
    .then((getData) => {
      setProfileApiData(getData); 
      // console.log(profileApiData.data.data); 
    })
    .catch(e => {
      console.log(e); 
    })
  }, [page])
  

  // console.log(profileApiData?.data);
  // console.log(profileApiData?.data?.data?.jobs);
  console.log("apiData", apiData); 

  const [searchInput, setSearchInput] = useState(""); 

  const loadMore = () => {
    setLoading(true); 
    setPage(page + 1); 
  }


  return (
    <>
    <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="tradeWrapper" >
                <BusinessToggle/>

                {/* <div className='construction'>
                    <img className='profileUnderConstruction' src={image} alt="under construction" />
                </div> */}

                <div>
                  <input 
                    type="text" 
                    placeholder='Search' 
                    style={{ 
                      margin: '10px 0 8px 0', 
                      borderRadius: '20px', 
                      padding: '0px 10px', 
                      outline: 'none', 
                      fontSize: '14px' 
                    }} 
                    onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>    

                <div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto'}}>
                {apiData
                .filter((value) => {
                  if(searchInput === "") {
                    return value; 
                  } else if (
                    value.name
                    .toLowerCase()
                    .includes(searchInput.toLowerCase())
                  ) {
                    return value; 
                  }
                })
                .map((data) => {
                  return (
                    <TradeJobs key={data.id} trade={data} matri={profileApiData?.data?.data?.matrimony} job={profileApiData?.data?.data?.jobs}/>
                  )
                })}
                </div>

                {loading && <Loader/>}

                <p onClick={loadMore} style={{
                  backgroundColor: 'var(--primary)',
                  color: 'white',
                  padding: '5px 8px',
                  width: 'fit-content',
                  borderRadius: '10px', 
                  margin: '10px auto',
                  cursor: 'pointer'
                }}>Load More</p>
            </div>
            <Rightbar/>
        </div>
    </div>
    </>
  )
}
