import React from "react";
import "./sidebar.css";
// import Login from '../../pages/login/Login';
import { Link, useNavigate } from "react-router-dom";

// import Home from '../../pages/home/Home'

// For Icons
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import ForumIcon from "@mui/icons-material/Forum";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import GroupsIcon from "@mui/icons-material/Groups";
import WorkIcon from "@mui/icons-material/Work";
import SecurityIcon from "@mui/icons-material/Security";
import AddCardIcon from "@mui/icons-material/AddCard";
import LoginIcon from "@mui/icons-material/Login";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// import { Login } from '@mui/icons-material';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
// import { Link } from '@mui/material';
// import NewspaperIcon from '@mui/icons-material/Newspaper';
import HeadphonesIcon from "@mui/icons-material/Headphones";
import PaymentsIcon from "@mui/icons-material/Payments";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import BusinessIcon from "@mui/icons-material/Business";
import CampaignIcon from "@mui/icons-material/Campaign";
import ErrorIcon from "@mui/icons-material/Error";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import StorefrontIcon from "@mui/icons-material/Storefront";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { ReactComponent as RefundIcon } from "../../assets/refund.svg";

import { useSelector } from "react-redux";
// import { useState } from 'react';

export default function Sidebar() {
  // const name = useSelector((state) => state.user.name)

  const name = window.localStorage.getItem("name");

  const navigate = useNavigate();
  const logout = () => {
    console.log("logout Clicked");
    window.localStorage.clear();
    // window.localStorage.clear();
    // window.location.reload();
    navigate("/");
  };

  return (
    <div className="sidebar">
      <div className="sidebarStrip"></div>
      <div className="sidebarWrapper">
        <ul className="sidebarList">
          <Link to="/home" className="sidebarListItemLink">
            <DynamicFeedIcon className="sidebarIcon" />
            <span className={`sidebarListItemText`}>
              {/* <Link to='../../pages/login/Login'>Feed.</Link> */}
              Feed
            </span>
          </Link>

          <Link to="/news" className="sidebarListItemLink">
            <LanguageIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Around</span>
          </Link>

          <li className="sidebarListItem">
            <ForumIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Chats</span>
          </li>

          <Link to="/profile" className="sidebarListItemLink">
            <PersonIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Profile</span>
          </Link>

          {/* <Link to="/payment" className="sidebarListItemLink">
            <CurrencyRupeeIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Payment</span>
          </Link> */}

          <Link to="/business" className="sidebarListItemLink">
            <BusinessCenterIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Business</span>
          </Link>

          <Link to="/business/premium" className="sidebarListItemLink">
            <WorkspacePremiumIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Premium</span>
          </Link>

          <Link to="/business/listing" className="sidebarListItemLink">
            <StorefrontIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Listing</span>
          </Link>

          <Link to="/reels" className="sidebarListItemLink">
            <SlowMotionVideoIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Reels</span>
          </Link>

          {/* <Link to="/payment/history" className="sidebarListItemLink">
            <PaymentsIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Payment History</span>
          </Link> */}

          <Link to="/badge" className="sidebarListItemLink">
            <MilitaryTechIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Request For Badge</span>
          </Link>

          <Link to="/businessacc" className="sidebarListItemLink">
            <BusinessIcon className="sidebarIcon" />
            <span className="sidebarListItemText">
              Apply For Business Account
            </span>
          </Link>

          <Link to="/advertiseUs" className="sidebarListItemLink">
            <AddCardIcon className="sidebarIcon" />
            <span className="sidebarListItemText sidebarAdv">
              Advertise with us
            </span>
          </Link>

          <Link to="/privacy-policy" className="sidebarListItemLink">
            <SecurityIcon className="sidebarIcon" />
            <span className="sidebarListItemText">
              <p>Privacy Policy</p>
            </span>
          </Link>

          <Link to="/terms-of-use" className="sidebarListItemLink">
            <WorkIcon className="sidebarIcon" />
            <span className="sidebarListItemText">
              <p>Terms of use</p>
            </span>
          </Link>

          <Link to="/community-standards" className="sidebarListItemLink">
            <GroupsIcon className="sidebarIcon" />
            <span className="sidebarListItemText">
              <p>Community Standards</p>
            </span>
          </Link>

          <Link to="/business-policy" className="sidebarListItemLink">
            <LocalMallIcon className="sidebarIcon" />
            <span className="sidebarListItemText">
              <p>Business Policy</p>
            </span>
          </Link>

          <Link to="/disclaimer" className="sidebarListItemLink">
            <ErrorIcon className="sidebarIcon" />
            <span className="sidebarListItemText">
              <p>Disclaimer</p>
            </span>
          </Link>

          <Link to="/advertisement-policy" className="sidebarListItemLink">
            <CampaignIcon className="sidebarIcon" />
            <span className="sidebarListItemText">
              <p>Advertisement Policy</p>
            </span>
          </Link>
          <Link
            to="/refund-and-cancellation-policy"
            className="sidebarListItemLink"
          >
            <RefundIcon className="sidebarIcon refundIcon" />
            <span className="sidebarListItemText">
              <p>Refund & Cancellation</p>
            </span>
          </Link>

          <Link to="/contactUs" className="sidebarListItemLink">
            <HeadphonesIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Contact Us</span>
          </Link>
          <Link to="/services-offered" className="sidebarListItemLink">
            <MiscellaneousServicesIcon className="sidebarIcon" />
            <span className="sidebarListItemText">Services Offered</span>
          </Link>
          <li className="sidebarListItemLink sidebarSocialLinks">
            <InstagramIcon className="sidebarIcon" onClick={() => window.open("https://www.instagram.com/trendingsaroundapp", "_blank")} />
            <YouTubeIcon className="sidebarIcon" onClick={() => window.open("https://youtube.com/@trendingshadow1?si=cxTe5T1nWUEMaPyq", "_blank")} />
            <LinkedInIcon className="sidebarIcon" onClick={() => window.open("https://www.linkedin.com/company/trendings-around%C2%AE/", "_blank")} />
          </li>

          <li className="sidebarListItemLink" onClick={logout}>
            <LoginIcon className="sidebarIcon" />
            <div className="sidebarListItemText">
              <span>Logout ( {name} )</span>
            </div>
          </li>
        </ul>

        {/* <button className="sidebarButton">Show More</button> */}
        {/* <hr className="sidebarHr" /> */}

        {/* <p style={{ fontSize: '20px', textAlign: 'center', paddingBottom: '15px' }}>Online Users</p>
        <ul className="sidebarFriendList">
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Deepesh Attri</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Arsh Ting Ting</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">David</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Akshita Singh</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Jordy</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Deepak Garg</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Deepesh Attri</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Arsh Ting Ting</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">David</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Akshita Singh</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Jordy</span>
          </li>
          <li className="sidebarFriend">
            <img src="/assets/turtle.jpg" alt="" className="sidebarFriendImage" />
            <span className="sidebarFriendName">Deepak Garg</span>
          </li>
        </ul> */}
      </div>
    </div>
  );
}

// Adding a comment at the end of the sidebar.js

// Adding second comment.

// https://ahmedabadsubha.com/terms-of-use  --> terms of use
// https://ahmedabadsubha.com/privacy       --> privacy policy
