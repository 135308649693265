import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import './payment.css'
import qr from '../../assets/qr.jpg'
import { useState } from 'react'
import axios from 'axios'
// import { amber } from '@mui/material/colors'

import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import Loader from '../../loader/Loader'

const Payment = () =>{

    const uid = window.localStorage.getItem("uid"); 

    const [date, setDate] = useState(""); 
    const [selectedPayment, setSelectedPayment] = useState(""); 
    const [transactionId, setTransactionId] = useState(""); 
    const [time, setTime] = useState(""); 

    const [amt, setAmt] = useState(0); 

    const handlePayment = (e) =>{
        const value = e.target.value;
        setSelectedPayment(value);

        if (value === 'Strip') {
            setAmt(499);
        } else if (value === 'Priority Strip') {
            setAmt(5000);
        } else if (value === 'Classified') {
            setAmt(99);
        } else if (value === 'Listing Of Business') {
            setAmt(1099);
        } else if (value === 'Matrimonial Profile') {
            setAmt(349);
        } else if (value === 'Post Vacancy') {
            setAmt(499); 
        }
    }

    if(selectedPayment === "strip") {
        
    }
    console.log(amt)

    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false); 
    const navigate = useNavigate(); 

    const handleSubmit = async () => {

        axios.post("https://ahmedabadsubha.com/api/mobile/payment/create", {
            "payment_for_service": selectedPayment,
            "transaction_id": transactionId, 
            "amount": amt,
            "payment_status": "Pending", 
            "payment_date": date, 
            "payment_time": time, 
            "uid": uid, 
            "user": uid
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then ((res) => {
            console.log(res); 
            toast.success("Submitted Successfully", {
                position: "top-center"
            }); 
            setSubmitButtonDisabled(true); 
            setTimeout(() => {
                navigate("/payment/history"); 
            }, 2000);
        }).catch (e => {
            console.log(e); 
            toast.error("Please fill all the fields", {
                position: "top-center"
            }); 
            setSubmitButtonDisabled(false); 
        })

    }

    return(
        <>
        <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="badgeWrapper">
                {qr ?
                <div className="qrContainer">
                    <img src={qr} alt="" className='qrImage'/>
                </div> 
                : 
                <Loader/>
                }
                <div className="paymentInfoContainer">
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                        {selectedPayment === "Strip" && <>
                        <p>Instructions</p>
                        <p>• <strike>₹998</strike><span>(50% 0ff) ₹499 - one day</span></p>
                        </>}
                        {selectedPayment === "Priority Strip" && <>
                        <p>Instructions</p>
                        <p>• <strike>₹10000</strike><span>(50% 0ff) ₹5000 - one day</span></p>
                        </>}
                        {selectedPayment === "Classified" && <>
                        <p>Instructions</p>
                        <p>• Free for individual.</p>
                        <p>• <strike>₹198</strike><span>(50% 0ff) ₹99 - one month for business only</span></p>
                        </>}
                        {selectedPayment === "Listing Of Business" && <>
                        <p>Instructions</p>
                        <p>• <strike>₹5495</strike><span>(50% 0ff) ₹1099 - 6 months</span></p>
                        <p>• <strike>₹9995</strike><span>(50% 0ff) ₹1999 - 1 year</span></p>
                        <p>• <strike>₹17495</strike><span>(50% 0ff) ₹3499 - 2 years</span></p>
                        <p>• <strike>₹24495</strike><span>(50% 0ff) ₹4899 - 3 years</span></p>
                        </>}
                        {selectedPayment === "Matrimonial Profile" && <>
                        <p>Instructions</p>
                        <p>• <strike>₹698</strike><span>(50% 0ff) ₹349 - 1 months</span></p>
                        <p>• <strike>₹1998</strike><span>(50% 0ff) ₹1999 - 3 months</span></p>
                        <p>• <strike>₹3598</strike><span>(50% 0ff) ₹3499 - 6 months</span></p>
                        <p>• <strike>₹5998</strike><span>(50% 0ff) ₹4899 - 12 months</span></p>
                        </>}
                        {selectedPayment === "Post Vacancy" && <>
                        <p>Instructions</p>
                        <p>• <strike>₹998</strike><span>(50% 0ff) ₹499 - 1 months</span></p>
                        <p>• <strike>₹1798</strike><span>(50% 0ff) ₹899 - 3 months</span></p>
                        <p>• <strike>₹2598</strike><span>(50% 0ff) ₹1299 - 6 months</span></p>
                        </>}
                    </div>

                    <select onChange={handlePayment} name="advWithUs" id="advWithUs">
                        <option value="No option chosen">Select Payment For</option>
                        <option value="Strip">Strip</option>
                        <option value="Priority Strip">Priority Strip</option>
                        <option value="Classified">Classified</option>
                        <option value="Listing Of Business">Listing Of Business</option>
                        {/* <option value="Matrimonial Profile">Matrimonial Profile</option> */}
                        <option value="Post Vacancy">Post Vacancy</option>
                    </select>

                    <div>
                    <label htmlFor="name">Transaction ID / Reference ID <span style={{ color: 'red' }}>*</span></label>
                    <input 
                    className='styleInput'
                    type="text"
                    id='name'
                    name='name'
                    placeholder='Transaction ID / Reference ID'
                    onChange={e => setTransactionId(e.target.value)}
                    />
                    </div>


                    <div className="paymentDateTime">
                        <div>
                        <label htmlFor="date">Date <span style={{ color: 'red' }}>*</span></label>
                        <input 
                        className='styleInput'
                        type="date"
                        pattern="\d{4}-\d{2}-\d{2}"
                        id='date'
                        name='name'
                        placeholder='Date'
                        onChange={e => setDate(e.target.value)}
                        />
                        </div>

                        <div>
                        <label htmlFor="time">Time <span style={{ color: 'red' }}>*</span></label>
                        <input 
                        className='styleInput'
                        type="time"
                        id='time'
                        name='name'
                        placeholder='Time'
                        onChange={e => setTime(e.target.value)}
                        />
                        </div>
                    </div>

                    <button onClick={handleSubmit} disabled={submitButtonDisabled} className='paymentSubmit'>Submit</button>

                </div>
            </div>
            <Rightbar/>
        </div>
    </div>
    <ToastContainer/>
        </>
    )
}

export default Payment; 