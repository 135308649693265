// import Search from '@mui/icons-material/Search'
import React from 'react'
import './topbar.css'
import { Link } from 'react-router-dom'

// this is the dropdowm component for the profile dropdown menu. 

export default function DropdowmItem(props) {
  return (
    <div className='dropdown-item'>
      <div className='mui-icons'>{props.TheImage}</div>
      <Link style={{ color: 'var(--primary)', textDecoration: 'none' }} to={props.to}>{props.text}</Link>
    </div>
  )
}
