import React from 'react'
import { Navigate } from 'react-router-dom';
import { useUserAuth } from './UserAuthContext';

export default function ProtectedRoutes( {children} ) {

  let { user } = useUserAuth(); 
  if(user) {
    window.localStorage.setItem("isLoggedIn", true)
  }

  const loggedIn = window.localStorage.getItem("isLoggedIn")

  if (loggedIn === false) {
    return <Navigate to="/"/>
  }
  return children; 
}
