import React from 'react'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import BusinessToggle from '../../components/businessToggle/BusinessToggle'
import Rightbar from '../../components/rightbar/Rightbar'
// import { Link } from 'react-router-dom'

import './form.css'

export default function JobForm() {
  return (
    <>
     <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="tradeWrapper">
                <BusinessToggle/>
                <div>
                <form style={{
                    margin: 'auto',
                    padding: '15px',
                    maxWidth: '400px',
                    alignContent: 'center'
                    }}
                    // onSubmit={handleSubmit}
                    > 
                    
                    <label htmlFor='name'>Name</label>
                    <input 
                    className='styleInput'
                    type="text"
                    id='name'
                    name='name'
                    // placeholder='Your Name...'
                    // value={name}
                    // onChange={ (e) => setName(e.target.value)} 
                    />

                    <label htmlFor='name'>Email</label>
                    <input 
                    className='styleInput'
                    type="email"
                    id='email'
                    name='email'
                    // placeholder='Your Email...'
                    // value={email}
                    />

                    <label htmlFor='contact'>Contact</label>
                    <input 
                    className='styleInput'
                    type="number"
                    id='contact'
                    name='contact'
                    placeholder='Your Contact...'
                    // value={contact}
                     />

                    <label htmlFor='name'>Educational Qualification</label>
                    <input 
                    className='styleInput'
                    type="text"
                    id='text'
                    name='text'
                    // placeholder='...'
                    // value={email}
                    />

                    <label htmlFor='name'>Work Experience</label>
                    <input 
                    className='styleInput'
                    type="text"
                    id='text'
                    name='text'
                    placeholder='Work Experience'
                    // value={email}
                    />  

                    <label htmlFor='name'>Current Profile</label>
                    <input 
                    className='styleInput'
                    type="text"
                    id='text'
                    name='text'
                    placeholder='Current Profile'
                    />  

                    <label htmlFor='name'>CTC (Optional)</label>
                    <input 
                    className='styleInput'
                    type="text"
                    id='text'
                    name='text'
                    placeholder='CTC (Optional)'
                    />  

                    <input type="submit" value='save' className='formSave' />

                 </form>
                </div>
            </div>
            <Rightbar/>
        </div>
    </>
  )
}
