import React, { useState } from 'react'

import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';

export default function Trade(props) {

  const toggle = () => {
    setTradeOpen(!tradeOpen)
    if (tradeOpen === false) {
      setSee("More")
    } else{
      setSee("Less") 
    } 
  }

  const[tradeOpen, setTradeOpen] = useState(true); 
  const[see, setSee] = useState('More'); 
  
  // console.log(tradeOpen)

  return (
    <>
     <div className='trade-wrapper'>  
       <div className="trade">
        <div className="tradeWrapper">
            <div className="tradeTop">
                
                <img src={props.value.company_logo} alt="Company Logo" className="tradeMainImg" />
              
                <div className="tradeTopRight">
                  <div>
                    <div className="headingTop">
                      <h3 className='tradeHeading'>{props.value.job_title}</h3>  
                      <div className="eyeCount">
                        <RemoveRedEyeIcon/>
                        <p>{'89'}</p>
                      </div>
                    </div>
                    <p className='tradeDesc'>{props.value.name_of_organization}</p>  
                    <div className="tradeDetailBox">
                      <WorkHistoryIcon htmlColor='orange'/>
                      <p className='DetailDesc'>{props.value.total_experience}</p>
                    </div>      
                    <div className="tradeDetailBox">
                      <LocationOnIcon htmlColor='blue'/>
                      <p className='DetailDesc'>{props.value.state}</p>
                    </div>      
                    <div className="tradeDetailBox">
                      <HorizontalSplitIcon htmlColor='green'/>
                      <p className='DetailDesc'>{props.value.area_of_specialization}</p>
                    </div>     
                  </div> 
                  <div className={`subDetailContainer ${tradeOpen? 'tradeActive' : 'nothinng'}`}>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Job Description</p>
                      <p style={{ width: '60%'}}>{'props.trade.description'}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Area of Specialization</p>
                      <p style={{width: '60%'}}>{props.value.area_of_specialization}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Contact Person</p>
                      <p style={{width: '60%'}}>{props.value.contact_person_name }</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Educational Qaulification</p>
                      <p style={{width: '60%'}}>{props.value.educational_qualification}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Salary Offered</p>
                      <p style={{width: '60%'}}>{props.value.salary_offered}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>State</p>
                      <p style={{width: '60%'}}>{props.value.state}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Years of Experience</p>
                      <p style={{width: '60%'}}>{props.value.total_experience}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Year of Establishment</p>
                      <p style={{width: '60%'}}>{props.value.year_of_establishment}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Company Size</p>
                      <p style={{width: '60%'}}>{props.value.company_size}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Job Type</p>
                      <p style={{width: '60%'}}>{props.value.job_type}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Vacancies</p>
                      <p style={{width: '60%'}}>{props.value.no_of_post_vacant}</p>
                    </div>
                    <div className="tradeSubDetail">
                      <p style={{fontWeight: 'bold', width: '40%'}}>Webiste</p>
                      <p style={{width: '60%'}}>{props.value.website}</p>
                    </div>
                  </div>
                </div>

                <div className="tradeTopExtremeRight">
                  <div className="tradeChat" style={{ width: '100px'}}>
                    <ChatBubbleIcon/>
                    Message
                  </div>
                  <div onClick={() => toggle()} className="tradeSeeMore">
                    See {see}
                  </div>
                </div>
            </div>
            <div className="tradeCenter">
                <p className="tradeText">published: {props.value.updated_at.slice(0, 10)}</p>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}
