import React, { useEffect, useState } from "react";
import Share from "../share/Share";
import "./feed.css";
import axios from "axios";
import Post from "../../components/post/Post";
import Loader from "../../loader/Loader";
// import { Posts } from '../../inputData'

export default function Feed(props) {
  
  const debounce = (func, wait) => {
    let timeout;
    return () => {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  };

  const [apiData, setApiData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        apiData.length > 0 &&
        !loading
      ) {
        setLoading(true);
        setPage((prevPage) => prevPage + 1);
      }
    }, 300);

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [apiData, loading]);

  useEffect(() => {
    if (loading) {
      axios
        .get(`https://ahmedabadsubha.com/api/mobile/selfie?page=${page}`)
        .then((getData) => {
          setApiData((prevData) => [...prevData, ...getData.data.results]);
          setLoading(false);
        });
    }
  }, [loading, page]);

  console.log(apiData);

  return (
    <div className="feed">
      <div className="feedWrapper">
        {/* <Share /> */}

        {apiData
          .filter((value) => {
            if (props.feedSearch === "") {
              return value;
            } else if (
              value.user.name
                .toLowerCase()
                .includes(props.feedSearch.toLowerCase())
            ) {
              return value;
            }
          })
          .map((data) => {
            return <Post key={data.id} post={data} />;
          })}

        {loading && <Loader />}
      </div>
    </div>
  );
}
