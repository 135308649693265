import React, { useEffect, useState } from 'react'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import BusinessToggle from '../../components/businessToggle/BusinessToggle'
import Rightbar from '../../components/rightbar/Rightbar'

import axios from 'axios'
import RecruitDetail from './RecruitDetail'

import image from '../../assets/underConstruction.jpg'
import Loader from '../../loader/Loader'

export default function Recruiter() {

  const [apiData, setApiData] = useState([]); 
  const [page, setPage] = useState(2); 
  const [loading, isLoading] = useState(true); 

  useEffect(() => {
    axios.get(`https://ahmedabadsubha.com/api/mobile/job-posting?page=${page}`)
    .then((getData) => {
      setApiData((prevData) => [...prevData, ...getData.data.results]); 
      isLoading(false); 
    })
    .catch(e => console.log(e));
  }, [page])

  const [searchInput, setSearchInput] = useState(""); 

  const showMore = () => {
    isLoading(true); 
    setPage(page + 1); 
  }


  // console.log(apiData)

  return (
    <>
    <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="tradeWrapper">
                <BusinessToggle/>

                {/* <div className='construction'>
                    <img className='profileUnderConstruction' src={image} alt="under construction" />
                </div> */}
                
                <div>
                  <input 
                    type="text" 
                    placeholder='Search' 
                    style={{ 
                      margin: '10px 0 8px 0', 
                      borderRadius: '20px', 
                      padding: '0px 10px', 
                      outline: 'none', 
                      fontSize: '14px' 
                    }} 
                    onChange={(e) => setSearchInput(e.target.value)}
                    />
                </div>
                
                {/* <div style={{
                  display: 'flex',
                  width: '100%'
                }}>

                  <div>
                    <select 
                      name="" 
                      id="" 
                      onChange={""}
                      style={{ 
                        margin: '10px 0 8px 0', 
                        borderRadius: '20px', 
                        padding: '8px 10px', 
                        outline: 'none', 
                        fontSize: '14px', 
                        flex: 1
                      }} 
                      >
                          <option value="">State</option>
                          <option value="All">All</option>
                          <option value="Andhra Prades">Andhra Prades</option>
                          <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                          <option value="Assam">Assam</option>
                          <option value="Bihar">Bihar</option>
                          <option value="Chhattisgarh">Chhattisgarh</option>
                          <option value="Goa">Goa</option>
                          <option value="Gujarat">Gujarat</option>
                          <option value="Haryana">Haryana</option>
                          <option value="Himachal Pradesh">Himachal Pradesh</option>
                          <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                          <option value="Jharkhand">Jharkhand</option>
                          <option value="Karnataka">Karnataka</option>
                          <option value="Kerala">Kerala</option>
                          <option value="Madhya Pradesh">Madhya Pradesh</option>
                          <option value="Maharashtra">Maharashtra</option>
                          <option value="Manipur">Manipur</option>
                          <option value="Meghalaya">Meghalaya</option>
                          <option value="Mizoram">Mizoram</option>
                          <option value="Nagaland">Nagaland</option>
                          <option value="Odisha">Odisha</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Rajasthan">Rajasthan</option>
                          <option value="Sikkim">Sikkim</option>
                          <option value="Tamil Nadu">Tamil Nadu</option>
                          <option value="Telangana">Telangana</option>
                          <option value="Tripura">Tripura</option>
                          <option value="Uttarakhand">Uttarakhand</option>
                          <option value="Uttar Pradesh">Uttar Pradesh</option>
                          <option value="West Bengal">West Bengal</option>
                          <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                          <option value="Daman and Diu">Daman and Diu</option>
                          <option value="Delhi">Delhi</option>
                          <option value="Lakshadweep">Lakshadweep</option>
                          <option value="Puducherry">Puducherry</option>
                    </select>
                  </div>

                  <div>
                    <select 
                      name="" 
                      id=""
                      style={{ 
                        margin: '10px 0 8px 0', 
                        borderRadius: '20px', 
                        padding: '8px 10px', 
                        outline: 'none', 
                        fontSize: '14px',
                        width: '100%'
                      }} 
                      >
                      <option value="me">City</option>
                    </select>
                  </div>

                </div> */}

                <div style={{ display: 'flex', flexWrap: 'wrap', height: 'auto'}}>
                  {apiData
                  .filter((value) => {
                    if(searchInput === "") {
                      return value; 
                    } else if (
                      value.job_title
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                    ) {
                      return value; 
                    }
                  })
                  .map((data) => {
                    return (
                      <RecruitDetail key={data.id} value={data}/>
                    )
                    })}
                </div>

                {loading &&
                  <Loader/>
                }

                <p onClick={showMore} style={{
                  backgroundColor: 'var(--primary)',
                  color: 'white',
                  padding: '5px 8px',
                  width: 'fit-content',
                  borderRadius: '10px', 
                  margin: '10px auto',
                  cursor: 'pointer'
                }}>Load More</p>
            </div>
            <Rightbar/>
        </div>
    </div>
    </>
  )
}
