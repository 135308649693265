import React, { useState } from 'react'
import './blog.css'
// import axios from 'axios';


export default function Blog({ post }) {

  const [showMore, setShowMore] = useState(false)

  const showMoreItems = () => {
    setShowMore(!showMore)
  }

  return (
    <div className='blog-wrapper'>  
       <div className="blog">
        <div className="blogWrapper">
            <div className="blogTop">
                <div className="blogTopLeft">
                    <span className="blogCategoryName">{post.category}</span>
                </div>
                <div className="blogTopRight">
                  <span className="blogDate">{post.pub_date}</span>            
                </div>
            </div>
            <div className="blogCenter">  
                {post.video_upload ? 
                <video className='blogVideo' controls src={post.video_upload}/>
                : 
                <img src={post.image_1} alt="" className="blogImg" />
                }
                <p style={{ fontWeight: '450', padding: '4px 0 10px 0' }}>{post.headline}</p>
                <span className="blogText">
                  {
                    showMore ? post.content : post.content.slice(0, 210)
                  }
                <p onClick={showMoreItems} style={{ cursor: 'pointer', color: '#0066b4' }}>
                  {
                    post.content.length < 210 ? " " :  `${showMore ? "Show Less" : "Show More"}`
                  } 
                </p>
                </span>
            </div>
            <div className="blogBottom">
                
            </div>
        </div>
      </div>
    </div>
  )
}
