import React from 'react'

import TopbarNews from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import './reels.css'

export default function ReelUpload() {

  return (
    <div>
      <TopbarNews />

      <div className="homeContainer">

        <Sidebar/>

        <div className="blogWrapper">
          
        </div>

        <Rightbar/>
        
      </div>
    </div>
  )
}
