import React from "react";
import Topbar from "../../components/topbar/Topbar";
import Sidebar from "../../components/sidebar/Sidebar";
import Rightbar from "../../components/rightbar/Rightbar";

import "./advertiseUs.css";
import playstore from "../../assets/playstore.png";
import applelogo from "../../assets/applelogo.png";
import { ToastContainer } from "react-toastify";

export default function DownloadApp() {
    const android = () => {
        var link =
          "https://play.google.com/store/apps/details?id=com.shadow.trendingsaround&pcampaignid=web_share";
        window.open(link, "_blank");
      };
      const apple = () => {
        var link = "https://apps.apple.com/in/app/trendings-around/id6465681185";
        window.open(link, "_blank");
      };

  return (
    <>
      <div>
        <Topbar />
        <div className="businessContainer">
          <Sidebar />
          <div className="badgeWrapper">
            <div>
              <h3 className="advertiseUsHeading">Advertise With Us</h3>
            </div>
            <div>
            <div className="messageContainer_downloadAppPage">
                <p
                  style={{
                    color: "var(--primary)",
                    textAlign: "center",
                    padding: '10px',
                    fontSize: '15px'
                  }}
                >
                 For applying advertisements with us kindly use our app available on play store and app store
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: "auto",
                    cursor: "pointer",
                    paddingTop: "10px",
                  }}
                >
                  <img
                    src={playstore}
                    onClick={android}
                    style={{ width: "80px" }}
                    alt=""
                  />
                  <img
                    src={applelogo}
                    onClick={apple}
                    style={{ width: "140px" }}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <Rightbar />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
