import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';

import './profile.css'

export default function ImageModal( { closeModal, file, updateProfileImg }) {
    console.log(file)
    const blob = new Blob([file], { type: file.type });
    const imgURL = URL.createObjectURL(blob); 
    console.log(blob)
    console.log(imgURL)

  return (
    <>
        <div className="modal-wrapper" onClick={ closeModal } ></div>
        <div className="imageModalContainer">
            <CancelIcon onClick={closeModal}/>
            <div className="profileImageModal">
              {file &&
              <img src={file} alt="profile pic" />
              }
            </div>
            this is  the modal for image. 
        <p className='profilePicUploadButton' onClick={ updateProfileImg }>Upload</p>
        </div>
        </>
  )
}
