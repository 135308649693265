import React from "react";
import AroundToggle from "../../components/aroundToggle/AroundToggle";

import './quiz.css'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'
import axios from 'axios'

import CampaignIcon from '@mui/icons-material/Campaign';
import { useNavigate } from "react-router-dom";

export default function Quiz () {

    const navigate = useNavigate(); 

    const play = () => {
        navigate('/playQuiz');
    }

    return (
        <>
    <div >
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="blogWrapper">
                <AroundToggle/>
                
                <div className="quizSpeakerContainer">
                    <div  className="speakerIconContainer"><CampaignIcon className="speakerIcon"/></div>
                    <p className="quizPlayButton" onClick={play}>PLAY</p>
                </div>
            </div>
            <Rightbar/>
        </div>
    </div>
    </>
    )
} 