import React from 'react'
import "./video.css"
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { useNavigate } from 'react-router-dom';

function ReelHeader() {

    const naviagate = useNavigate(); 
    const reelUpload = () => {
        naviagate('/reels/upload'); 
    } 

    return (
        <div className="videoHeader">
            <h2>Clips</h2>
            <VideoCallIcon onclick={reelUpload} style={{ fontSize: '40px' }}/>
        </div>
    )
}

export default ReelHeader
