import React from "react";

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import './contact.css'

export default function Contact () {
    return (
        <>
         <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="tradeWrapper">
                <h3 className="businessAccHeading">Contact Us</h3>
                <div className="contactContainer">
                    <h3 className="contactSubHeading">For Complaints and Suggestions</h3>
                    <div className="contactMail">
                        <MailOutlineIcon/>
                        <p>customercare@ahmedabadsubha.com</p>
                    </div>

                    <h3 className="contactSubHeading">For Corporate Enquiries</h3>
                    <div className="contactMail">
                        <MailOutlineIcon/>
                        <p>info@ahmedabadsubha.com</p>
                    </div>
                </div>
            </div>
            <Rightbar/>
        </div>
        </>
    )
}