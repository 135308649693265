import React, { useEffect, useState } from "react";
import AroundToggle from "../../components/aroundToggle/AroundToggle";

import './quiz.css'

import Topbar from '../../components/topbar/Topbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Rightbar from '../../components/rightbar/Rightbar'
// import { useNavigate } from "react-router-dom";
import axios from "axios";
// import axios from 'axios'


export default function PlayQuiz () {

    const [apiData, setApiData] = useState(""); 

    const uid = window.localStorage.getItem("uid"); 
    
    const once = async () => {
        await axios.get(`https://ahmedabadsubha.com/api/mobile/quiz?uid=${uid}`)
        .then((getData) => {
            setApiData(getData); 
            console.log(apiData); 
        }) 
        .catch ((e) => {
            console.log(e); 
        })
    }

    useEffect(() => {
        once(); 
    }, [])

    return (
        <>
    <div>
        <Topbar/>
        <div className="businessContainer">

            <Sidebar/>
            <div className="blogWrapper">
                <div className="quizContainer">
                    <div className="quizCard">
                        <p style={{
                            fontSize: '20px', 
                            paddingTop: '10px', 
                            paddingLeft: '10px'
                        }}>12 Oct 2023 Quiz</p>
                        <p style={{
                            padding: '10px 10px', 
                            cursor: 'pointer'
                        }}>Play Now</p>
                    </div>
                </div>
            </div>
            <Rightbar/>
        </div>
    </div>
    </>
    )
} 