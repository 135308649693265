import React, { useEffect, useState } from 'react'; 
import "./rightbar.css"; 
import Classified from '../adsBlock/Classified';
import axios from 'axios';

export default function Rightbar({profile}) {

  const  [adsData, setAdsData] = useState([]); 
  
  useEffect(() => { 
    axios.get("https://ahmedabadsubha.com/api/mobile/classified")
    .then((getData) => {
        setAdsData(getData.data.data)
    })
  },[])

  // console.log(adsData);

  return (

    <div className='rightbar'>
      {/* <Classified/> */}

      <h3 className='ads-heading'>Classified Ads</h3>

      {adsData.map((data) => {
          return(
            <Classified key={data.id} post={data} /> 
          )
        })}

    </div>
  )
}


// ads api --> https://ahmedabadsubha.com/api/mobile/classified