import React, { useState } from "react";
import "./trade.css";

import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ReviewsIcon from "@mui/icons-material/Reviews";

import noimage from "../../assets/noimage.jpeg";
import ReviewModal from "./ReviewModal.jsx";
import ImageSlider from "../imageSlider/ImageSlider";
import { useNavigate } from "react-router-dom";

export default function TradeRedirect(props) {
  const toggle = () => {
    setTradeOpen(!tradeOpen);
    if (tradeOpen === false) {
      setSee("More");
    } else {
      setSee("Less");
    }

    // console.log(a);
    if (a === view) {
      setView(view + 1);
    } else {
      setView(view);
    }
  };

  console.log("from trade redirect", props);
  const navigate = useNavigate();
  const navigateFn = () => {
    navigate(`/business/premium/${props?.trade?.id}`)
  };

  const [tradeOpen, setTradeOpen] = useState(true);
  const [see, setSee] = useState("More");

  // console.log(tradeOpen)

  let a = props.trade.views;
  const [view, setView] = useState(props.trade.views);

  const [openModal, setOpenModal] = useState(false);
  const reviewModal = () => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const slides = [];
  for (let i = 1; i <= 10; i++) {
    const image = props.trade[`image_${i}`];
    if (image !== null) {
      slides.push(image);
    }
  }

  return (
    <>
      <div className="trade-wrapper">
        <div className="trade">
          <div className="tradeWrapper">
            <div className="tradeTop">
              {props.trade.image_1 ? (
                <>
                  <div className="tradeMainImg">
                    {props.trade.image_2 ? (
                      <div className="tradeMainImg">
                        <ImageSlider slides={slides} />
                      </div>
                    ) : (
                      <img
                        src={props.trade.image_1}
                        alt="business img"
                        className="tradeMainImg"
                      />
                    )}
                  </div>
                </>
              ) : (
                <img
                  src={noimage}
                  alt="business img"
                  className="tradeMainImg"
                />
              )}

              <div className="tradeTopRight">
                <div>
                  <div className="headingTop">
                    <h3 className="tradeHeading">{props.trade.brand_name}</h3>
                    <div className="eyeCount">
                      <RemoveRedEyeIcon />
                      <p>{view}</p>
                    </div>
                  </div>
                  <p className="tradeDesc">{props.trade.description}</p>
                  <div className="tradeDetailBox onlyForPaddingInTrade">
                    <WorkHistoryIcon htmlColor="orange" />
                    <p className="DetailDesc">{props.trade.business_type}</p>
                  </div>
                  <div className="tradeDetailBox">
                    <LocationOnIcon htmlColor="blue" />
                    <p className="DetailDesc">{props.trade.state}</p>
                  </div>
                  <div className="tradeDetailBox">
                    <HorizontalSplitIcon htmlColor="green" />
                    <p className="DetailDesc">{props.trade.target_audience}</p>
                  </div>
                  <div
                    className="tradeDetailBox"
                    style={{ cursor: "pointer" }}
                    onClick={reviewModal}
                  >
                    <ReviewsIcon htmlColor="var(--primary)" />
                    <p className="DetailDesc">Reviews</p>
                  </div>
                  {openModal && (
                    <ReviewModal
                      ad_id={props.trade.id}
                      review={props.trade.reviews}
                      closeModal={closeModal}
                    />
                  )}
                </div>
                <div
                  className={`subDetailContainer ${
                    tradeOpen ? "tradeActive" : "nothinng"
                  }`}
                >
                  <div className="tradeSubDetail">
                    <p className="tradeSubDetail01p">Description</p>
                    <p className="tradeSubDetail02p">
                      {props.trade.description}
                    </p>
                  </div>
                  <div className="tradeSubDetail">
                    <p className="tradeSubDetail01p">Business Type</p>
                    <p className="tradeSubDetail02p">
                      {props.trade.business_type}
                    </p>
                  </div>
                  <div className="tradeSubDetail">
                    <p className="tradeSubDetail01p">Target Locations</p>
                    <p className="tradeSubDetail02p">
                      {props.trade.target_locations}
                    </p>
                  </div>
                  <div className="tradeSubDetail">
                    <p className="tradeSubDetail01p">Business Reg No.</p>
                    <p className="tradeSubDetail02p">
                      {props.trade.business_registration_number}
                    </p>
                  </div>
                  <div className="tradeSubDetail">
                    <p className="tradeSubDetail01p">Business Reg Date</p>
                    <p className="tradeSubDetail02p">
                      {props.trade.business_registration_date}
                    </p>
                  </div>
                  <div className="tradeSubDetail">
                    <p className="tradeSubDetail01p">GST</p>
                    <p className="tradeSubDetail02p">
                      {props.trade.gst_number}
                    </p>
                  </div>
                  <div className="tradeSubDetail">
                    <p className="tradeSubDetail01p">PAN</p>
                    <p className="tradeSubDetail02p">
                      {props.trade.pan_number}
                    </p>
                  </div>
                </div>
              </div>

              <div className="tradeTopExtremeRight">
                <div className="tradeChat">
                  <ChatBubbleIcon />
                  Chat
                </div>
                <div onClick={navigateFn} className="tradeSeeMore">
                  See More
                </div>
              </div>
            </div>
            <div className="tradeCenter">
              <p className="tradeText">
                published: {props.trade.updated_at.slice(0, 10)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
